import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getData, getLog } from "./machineDataAPI";

const initialState = {
  status: "idle",
  error: null,
  machines: [],
  logstatus: "idle",
  logerror: null,
  logs: [],
};

export const getMachineDataAsync = createAsyncThunk(
  "machineData/getMachineData",
  async () => {
    const response = await getData();
    return response.data;
  }
);

export const getMachineLogAsync = createAsyncThunk(
  "machineData/getMachineLog",
  async (machineId) => {
    const response = await getLog(machineId);
    return response.data;
  }
);

export const machineDataSlice = createSlice({
  name: "machineData",
  initialState,
  reducers: {
    clearData: (state, action) => {
      state.machines = [];
    },
    clearLogData: (state, action) => {
      state.logs = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMachineDataAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getMachineDataAsync.fulfilled, (state, action) => {
        state.machines = action.payload.result;
        state.status = "idle";
      })
      .addCase(getMachineDataAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getMachineLogAsync.pending, (state) => {
        state.logstatus = "loading";
        state.logerror = null;
      })
      .addCase(getMachineLogAsync.fulfilled, (state, action) => {
        state.logs = action.payload.result;
        state.logstatus = "idle";
      })
      .addCase(getMachineLogAsync.rejected, (state, action) => {
        state.logstatus = "failed";
        state.logerror = action.error.message;
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMachineData = (state) => state.machineData.machines;
export const selectMachineStatus = (state) => state.machineData.status;
export const selectMachineError = (state) => state.machineData.error;

export const selectLogData = (state) => state.machineData.logs;
export const selectLogStatus = (state) => state.machineData.logstatus;
export const selectLogError = (state) => state.machineData.logerror;

export const { clearData, clearLogData } = machineDataSlice.actions;

export default machineDataSlice.reducer;
