import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  currentCustomer: null,
  currentPage: null,
  pages: null,
  status: "idle",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentUserAndCustomer(state, action) {
      const user = action.payload;
      state.currentUser = user;
      state.currentCustomer = user.customer;
      state.pages = user.pages;

      // if (user.machines.lendth > 0) {
      //   state.currentMachine = user.machines[0];
      // }
    },

    LogoutCurrentUser(state, action) {
      state.currentUser = null;
      state.currentCustomer = null;
      state.pages = null;
      state.currentPage = null;
      state.accessToken = null;
      localStorage.removeItem("accessToken");
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentUser = (state) =>
  state?.login ? state.login.currentUser : null;
export const selectCurrentCustomer = (state) =>
  state?.login ? state.login.currentCustomer : null;
export const selectPages = (state) => (state?.login ? state.login.pages : null);

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const { setCurrentUserAndCustomer, LogoutCurrentUser } =
  loginSlice.actions;

export default loginSlice.reducer;
