import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./machinehealth.css";

import {
  BarGauge,
  Label,
  Export,
  Title,
  Font,
  Margin,
  Subtitle,
} from "devextreme-react/bar-gauge";

const MachineHealth = (props) => {
  const { machine, value } = props;
  const machineid = machine.id;

  const array = [value];

  const format = {
    type: "fixedPoint",
    precision: 0,
  };

  function customizeText({ valueText }) {
    return `${valueText} %`;
  }

  const barGaugeAttributes = {
    id: "gauge",
    class: "runningEfficiency",
  };

  const palette = (value) => {
    if (value < 80) {
      return ["#FF0000"];
    }

    if (value < 90) {
      return ["#FFA500"];
    }

    return ["#008000"];
  };

  return (
    <div key={"MH" + machine.id}>
      <BarGauge
        startValue={0}
        endValue={100}
        values={array}
        elementAttr={barGaugeAttributes}
        palette={palette(value)}
      >
        <Label indent={5} format={format} customizeText={customizeText}></Label>
        <Title text={machine.machineName} wordWrap={"none"}>
          <Font size={28} />
          <Subtitle text={"Health & Efficiency"}></Subtitle>
          <Margin top={0} bottom={0} left={0} right={0} />
        </Title>

        <Export enabled={false} />
        <Margin top={0} bottom={10} left={0} right={0} />
      </BarGauge>
    </div>
  );
};

export default MachineHealth;
