import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalyticsData,
  getAnalyticsDataAsync,
} from "../../../analyticsData/analyticsDataSlice";
import "./machineState.css";

const DataController = (props) => {
  const { updateChartData, machine, reportDate, setDataReady } = props;

  const dispatch = useDispatch();
  const analyticsData = useSelector(selectAnalyticsData);

  const parseISODate = useCallback((isoDate) => {
    const date = new Date(isoDate);
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }, []);

  const [machineId] = useState(machine?.id);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [processed, setProcessed] = useState(null);

  const startDateRef = useRef(startDate);
  const endDateRef = useRef(endDate);

  useEffect(() => {
    //setEndDate(null);
    setStartDate(reportDate[0]);
    setEndDate(reportDate[1]);
  }, [reportDate]);

  useEffect(() => {
    startDateRef.current = startDate;
    endDateRef.current = endDate;
  }, [startDate, endDate]);

  const processDeviceData = useCallback(
    (rawData, start, end) => {
      if (!rawData || rawData.length === 0) {
        return [];
      }

      const parsedStartDate = parseISODate(start);
      const parsedEndDate = parseISODate(end);

      const parsedData = rawData.map((item) => JSON.parse(item));
      const devices = Object.keys(parsedData[0]).filter(
        (key) => key !== "DateTime"
      );
      const results = [];

      devices.forEach((device) => {
        let currentState = "OFF";
        let currentStartTime = parsedStartDate;

        parsedData.forEach((entry, index) => {
          const value = entry[device];
          const dateTime = parseISODate(entry.DateTime);
          const state = value > 0 ? "ON" : "OFF";

          if (index === 0 && dateTime > parsedStartDate) {
            results.push({
              Device: device,
              State: "OFF",
              start: parsedStartDate,
              end: dateTime,
            });
          }

          if (currentState !== state) {
            results.push({
              Device: device,
              State: currentState,
              start: currentStartTime,
              end: dateTime,
            });
            currentState = state;
            currentStartTime = dateTime;
          }

          if (index === parsedData.length - 1) {
            results.push({
              Device: device,
              State: currentState,
              start: currentStartTime,
              end: dateTime,
            });

            if (dateTime < parsedEndDate) {
              results.push({
                Device: device,
                State: currentState,
                start: dateTime,
                end: parsedEndDate,
              });
            }
          }
        });
      });

      return results;
    },
    [parseISODate]
  );

  // Fetch new analytics data when dates or machineId change
  useEffect(() => {
    if (startDate && endDate && startDate !== endDate) {
      setDataReady(false); // Show loading state before fetching new data
      dispatch(getAnalyticsDataAsync({ machineId, startDate, endDate }));
    }
  }, [dispatch, machineId, startDate, endDate, setDataReady]);

  // Process data once new analytics data is fetched
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (startDate && endDate && analyticsData) {
      const data = processDeviceData(
        analyticsData,
        startDateRef.current,
        endDateRef.current
      );
      setProcessed(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsData, processDeviceData]); // Only run when analyticsData changes

  // Update chart data and set data ready to true after processing
  useEffect(() => {
    if (processed) {
      updateChartData(processed);
      setDataReady(true);
    }
  }, [processed, updateChartData, setDataReady]);

  return (
    <div className="controllerInner">
      {/* <ul className="configUList">
        {sortedConfigs.map((config) => (
          <React.Fragment key={"config-fragment-" + config.id}>
            <li className="lineItem">
              <div className="dx-field">
                <div className="dx-field-label">{config.configName}</div>
                <div className="dx-field-value">
                  <CheckBox
                    className="checkbox"
                    defaultValue={false}
                    iconSize={20}
                  />
                </div>
              </div>
            </li>
          </React.Fragment>
        ))}
      </ul> */}
    </div>
  );
};

export default DataController;
