import { sendRequest } from "./utils.js";
import config from "../../config.json";
import {
  getData,
  deleteData,
  updateData,
  insertData,
  getCompanies,
  getPages,
} from "./machinesDataAPI.js";

export const FETCH_PENDING = "FETCH_PENDING";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";
export const SAVING_PENDING = "SAVING_PENDING";
export const SAVING_SUCCESS = "SAVING_SUCCESS";
export const SAVING_ERROR = "SAVING_ERROR";
export const SAVING_CANCEL = "SAVING_CANCEL";
export const SET_CHANGES = "SET_CHANGES";
export const SET_EDIT_ROW_KEY = "SET_EDIT_ROW_KEY";

export async function loadMachines(dispatch) {
  dispatch({ type: FETCH_PENDING });

  try {
    const { data } = await getData();
    const { companies } = await getCompanies();
    const { pages } = await getPages();

    dispatch({
      type: FETCH_SUCCESS,
      payload: {
        data: data.result,
        companies: companies.result,
        pages: pages.result,
      },
    });
  } catch (err) {
    dispatch({ type: FETCH_ERROR });
    throw err;
  }
}

export async function saveChange(dispatch, change) {
  if (change && change.type) {
    let data;

    dispatch({ type: SAVING_PENDING });

    try {
      data = await sendChange(change);

      dispatch({
        type: SAVING_SUCCESS,
        payload: {
          change,
        },
      });

      return data;
    } catch (err) {
      dispatch({ type: SAVING_ERROR });
      throw err;
    }
  } else {
    dispatch({ type: SAVING_CANCEL });
    return null;
  }
}

async function sendChange(change) {
  switch (change.type) {
    case "insert":
      return insertData({
        key: change.key,
        values: JSON.stringify(change.data),
      });
    case "update":
      return updateData({
        key: change.key,
        values: JSON.stringify(change.data),
      });
    case "remove":
      return deleteData(change.key); //sendRequest(`${url}/DeleteOrder`, "DELETE", { key: change.key });
    default:
      return null;
  }
}

export function setChanges(dispatch, changes) {
  dispatch({
    type: SET_CHANGES,
    payload: changes,
  });
}

export function setEditRowKey(dispatch, editRowKey) {
  dispatch({
    type: SET_EDIT_ROW_KEY,
    payload: editRowKey,
  });
}
