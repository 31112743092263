import http from "../../services/httpService";
import config from "../../config.json";

export async function getCompanies() {
  const response = await http.get(config.apiUrl + `/customers`);
  response.companies = response.data;
  delete response.data;
  return response;
}

export async function getData() {
  const response = await http.get(config.apiUrl + `/pages`);
  return response;
}

export async function deleteData(id) {
  const response = await http.delete(config.apiUrl + `/pages/${id}`);
  return response;
}

export async function updateData(data) {
  const response = await http.patch(
    config.apiUrl + `/pages/${data.key}`,
    data.values,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
}

export async function insertData(data) {
  const response = await http.post(
    config.apiUrl + "/pages",
    data.values, // Assuming data.values contains the new data to be inserted
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
}
