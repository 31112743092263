import React, { useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { useAuth } from "../../contexts/auth";
import "./UserPanel.scss";
import { LogoutCurrentUser } from "../../login/loginSlice";
import { clearData, clearLogData } from "../../machineData/machineDataSlice";

export default function UserPanel({ menuMode }) {
  const dispatch = useDispatch();

  const { user, signOut } = useAuth();

  const doReduxSignOut = useCallback(() => {
    signOut();
    dispatch(LogoutCurrentUser());
    dispatch(clearData());
    dispatch(clearLogData());
  }, [dispatch, signOut]);

  const navigate = useNavigate();

  // const navigateToProfile = useCallback(() => {
  //   navigate("/profile");
  // }, [navigate]);

  const navigateToHome = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const menuItems = useMemo(
    () => [
      {
        text: "Home",
        icon: "home",
        onClick: navigateToHome,
      },
      // {
      //   text: "Profile",
      //   icon: "user",
      //   onClick: navigateToProfile,
      // },
      {
        text: "Logout",
        icon: "runner",
        onClick: doReduxSignOut,
      },
    ],
    [navigateToHome, doReduxSignOut]
  );
  return (
    <div className={"user-panel"}>
      <p>{user.email}</p>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position
            my={{ x: "center", y: "top" }}
            at={{ x: "center", y: "bottom" }}
          />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
