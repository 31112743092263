import React from "react";
import { LoadPanel } from "devextreme-react/load-panel";
import "whatwg-fetch";
import reducer from "./reducer.js";
import {
  saveChange,
  loadConfigs,
  setChanges,
  setEditRowKey,
} from "./configActions.js";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Toolbar,
  Item,
  Lookup,
  FormItem,
  Label,
  Editing,
  Button as GridButton,
  MasterDetail,
  Form,
} from "devextreme-react/data-grid";

import { ColCountByScreen } from "devextreme-react/form";

const ConfigDetailView = (props) => {
  const machineid = props.data.key;

  const initialState = {
    data: [],
    changes: [],
    editRowKey: null,
    isLoading: false,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    loadConfigs(dispatch, machineid);
  }, [machineid]);

  const onSaving = React.useCallback((e) => {
    e.cancel = true;
    e.promise = saveChange(dispatch, e.changes[0]);
  }, []);

  const onChangesChange = React.useCallback((changes) => {
    setChanges(dispatch, changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(dispatch, editRowKey);
  }, []);

  const calculateCellValueInGraph = (rowData) => {
    let _val;
    rowData.inGraph ? (_val = 1) : (_val = 0);
    return _val;
  };

  const setCellValueInGraph = (newData, value, currentRowData) => {
    value ? (newData.inGraph = 1) : (newData.inGraph = 0);
  };

  const calculateCellValueInGauge = (rowData) => {
    let _val;
    rowData.inGauge ? (_val = 1) : (_val = 0);
    return _val;
  };

  const setCellValueInGauge = (newData, value, currentRowData) => {
    value ? (newData.inGauge = 1) : (newData.inGauge = 0);
  };

  const onInitNewRow = (e) => {
    e.data.machineId = machineid;
  };

  return (
    <DataGrid
      id="gridDetailContainer"
      keyExpr="id"
      dataSource={state.data}
      repaintChangesOnly
      showBorders={true}
      columnAutoWidth={true}
      columnHidingEnabled={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      focusedRowEnabled={true}
      onSaving={onSaving}
      onInitNewRow={onInitNewRow}
    >
      <Editing
        mode="form"
        allowAdding
        allowDeleting
        allowUpdating
        changes={state.changes}
        onChangesChange={onChangesChange}
        editRowKey={state.editRowKey}
        onEditRowKeyChange={onEditRowKeyChange}
      >
        <Form colCount={1}>
          <Item itemType="group" caption="Sensor Config" colCount={4}>
            <Item dataField="configPort" />
            <Item dataField="VA_Multiplier" />
            <Item dataField="configName" />
            <Item dataField="configGroup" />
          </Item>
          <Item itemType="group" caption="Display" colCount={1}>
            <Item dataField="inGraph" />
            <Item dataField="inGauge" />
          </Item>
          <Item itemType="group" caption="Lower Red" colCount={3}>
            <Item dataField="configMin" />
            <Item itemType="empty" />
            <Item dataField="lowerRedDelay" />
          </Item>
          <Item itemType="group" caption="Lower Orange" colCount={3}>
            <Item dataField="configMinMarginal" />
            <Item itemType="empty" />
            <Item dataField="lowerOrangeDelay" />
          </Item>
          <Item itemType="group" caption="Green" colCount={3}>
            <Item dataField="configMinDesired" />
            <Item dataField="configMaxDesired" />
            <Item dataField="greenDelay" />
          </Item>
          <Item itemType="group" caption="Upper Orange" colCount={3}>
            <Item dataField="configMaxMarginal" />
            <Item itemType="empty" />
            <Item dataField="higherOrangeDelay" />
          </Item>
          <Item itemType="group" caption="Upper Red" colCount={3}>
            <Item dataField="configMax" />
            <Item itemType="empty" />
            <Item dataField="higherRedDelay" />
          </Item>
          <Item itemType="group" caption="Upper Red" colCount={3}>
            <Item dataField="tickInterval" colSpan={1} />

            <Item dataField="AlertEmail" colSpan={2} />
          </Item>
        </Form>
      </Editing>

      <Paging defaultPageSize={5} />

      <Column
        dataField="configPort"
        caption="Port"
        width={60}
        fixed={true}
      ></Column>
      <Column
        dataField="VA_Multiplier"
        caption="Mltply"
        width={60}
        dataType="number"
      />
      <Column
        dataField="configName"
        caption="Name"
        minWidth={200}
        fixed={true}
      />
      <Column dataField="configGroup" caption="Group" width={200} />
      <Column
        dataField="inGraph"
        caption="Graph"
        width={300}
        dataType="boolean"
        calculateCellValue={calculateCellValueInGraph}
        setCellValue={setCellValueInGraph}
        alignment="center"
      />
      <Column
        dataField="inGauge"
        caption="Gauge"
        width={300}
        dataType="boolean"
        calculateCellValue={calculateCellValueInGauge}
        setCellValue={setCellValueInGauge}
        alignment="center"
      />
      <Column
        dataField="configMin"
        caption="Lower Red Bottom"
        width={200}
        dataType="number"
      />
      <Column
        dataField="lowerRedDelay"
        caption="Lower Red Delay"
        width={200}
        dataType="number"
      />
      <Column
        dataField="configMinMarginal"
        caption="Lower Red Top, Lower Orange Bottom"
        width={200}
        dataType="number"
      />
      <Column
        dataField="lowerOrangeDelay"
        caption="Lower Orange Delay"
        width={200}
        dataType="number"
      />
      <Column
        dataField="configMinDesired"
        caption="Lower Orange Top, Green Bottom"
        width={200}
        dataType="number"
      />
      <Column
        dataField="configMaxDesired"
        caption="Green Top, Upper Orange Bottom"
        width={200}
        dataType="number"
      />
      <Column
        dataField="greenDelay"
        caption="Green Delay"
        width={200}
        dataType="number"
      />
      <Column
        dataField="configMaxMarginal"
        caption="Upper Orange Top, Upper Red Bottom"
        width={200}
        dataType="number"
      />
      <Column
        dataField="higherOrangeDelay"
        caption="Upper Orange Delay"
        width={200}
        dataType="number"
      />
      <Column
        dataField="configMax"
        caption="Upper Red Top"
        width={200}
        dataType="number"
      />
      <Column
        dataField="higherRedDelay"
        caption="Upper Red Delay"
        width={200}
        dataType="number"
      />
      <Column
        dataField="tickInterval"
        caption="Gauge Ticker Interval"
        width={200}
        dataType="number"
      />
      <Column
        dataField="AlertEmail"
        caption="Alert Email (comma separated)"
        width={200}
        dataType="email"
      />
    </DataGrid>
  );
};

export default ConfigDetailView;
