import http from "../services/httpService";
import config from "../config.json";

export async function getData() {
  const response = await http.get(config.apiUrl + `/machinesdata`);

  return response;
}

export async function getLog(machineId) {
  if (machineId) {
    const response = await http.get(
      config.apiUrl + `/machineslog/` + machineId
    );
    return response;
  } else {
    return [];
  }
}
