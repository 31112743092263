import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./home.scss";

import { useNavigate } from "react-router-dom";

import { selectCurrentUser, selectPages } from "../../login/loginSlice";
import MachineHealth from "./machineHealth";

import errorIcon from "../../assets/error48.png";
import warningIcon from "../../assets/warning48.png";
import naIcon from "../../assets/na48.png";
import offIcon from "../../assets/off48.png";
import okIcon from "../../assets/ok48.png";

const MachineState = (props) => {
  const { machine, selectedMachine, highlightMachine } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const machineid = machine.id;
  const pages = useSelector(selectPages);

  // Check if any config has IsError set to 1
  const hasError = machine.configs.some((config) => config.IsError === 1);

  const isSelected = selectedMachine === machine.id;

  const navigateToPage = () => {
    const page = pages.find((page) =>
      page.Machines.some((machine) => machine.id === machineid)
    );

    if (page) {
      navigate("/currentViewer", {
        state: {
          itemData: {
            page: page,
            key: machine.id + 1,
          },
        },
      });
    }
  };

  const efficiency = (machine) => {
    let percent = 100;
    if (machine.hasOwnProperty("configs") && Array.isArray(machine.configs)) {
      let configsLength = machine.configs.length;

      if (configsLength > 0) {
        let machinesOff = 0;
        machine.configs.forEach((config) => {
          if (config.Value === 0) {
            machinesOff += 1;
          }
        });

        configsLength = configsLength - machinesOff;

        const unit = 100 / configsLength;

        machine.configs.forEach((config) => {
          if (config.Value === 0) {
            percent = percent - unit;
          }

          if (config.Name === "lowOrange" || config.Name === "highOrange") {
            percent = percent - unit;
          }

          if (config.Name === "lowRed" || config.Name === "highRed") {
            percent = percent - unit * 2;
          }
        });
      } else {
        percent = 0;
      }
    } else {
      percent = 0;
    }

    if (percent < 0) {
      percent = 0;
    }

    return percent;
  };

  return (
    <div
      key={"M" + machine.id}
      className={`dx-card responsive-paddings content-block card ${
        hasError ? "error" : ""
      } ${isSelected ? "selected" : ""}`}
      onClick={() => highlightMachine(machine.id)}
      onDoubleClick={() => navigateToPage()}
    >
      <MachineHealth
        machine={machine}
        value={efficiency(machine)}
      ></MachineHealth>

      <div key={"sd" + machine.id}>
        {machine.configs.map((config, index) => (
          <div key={"s" + index}>
            <span className={"configname"} key={"sddd" + machine.id}>
              {config.IsError === 0 ? (
                config.Value === 0 ? (
                  <img src={offIcon} alt="Off" className="off-icon" />
                ) : (
                  <img src={okIcon} alt="Ok" className="ok-icon" />
                )
              ) : config.IsError === 1 ? (
                config.Name === "lowOrange" || config.Name === "highOrange" ? (
                  <img src={warningIcon} alt="Warning" className="error-icon" />
                ) : (
                  <img src={errorIcon} alt="Error" className="error-icon" />
                )
              ) : (
                <img src={naIcon} alt="Not Available" className="na-icon" />
              )}
            </span>
            <span className={"configname"}>{config.configName}</span>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MachineState;
