import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  signIn as sendSignInRequest,
  signInWithToken as sendSignInWithTokenRequest,
} from "../login/authAPI";
import { selectCurrentUser } from "../login/loginSlice";

const AuthContext = createContext({ loading: false, visible: true });

export const AuthProvider = (props) => {
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(true);

  const currentUser = useSelector(selectCurrentUser);
  const [axiosInterceptorId, setAxiosInterceptorId] = useState(null);

  const addAxiosInterceptor = useCallback((newToken) => {
    const id = axios.interceptors.request.use(
      (config) => {
        if (newToken) {
          config.headers["Authorization"] = `Bearer ${newToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    setAxiosInterceptorId(id);
  }, []);

  const removeAxiosInterceptor = useCallback(() => {
    if (axiosInterceptorId !== null) {
      axios.interceptors.request.eject(axiosInterceptorId);
      setAxiosInterceptorId(null);
    }
  }, [axiosInterceptorId]);

  const signInWithToken = useCallback(async () => {
    const blasterAuth = localStorage.getItem("blasterAuth");
    if (blasterAuth) {
      addAxiosInterceptor(blasterAuth);
    }

    const result = await sendSignInWithTokenRequest();

    if (result.isOk) {
      setUser(result.data);
      setToken(blasterAuth);
    }
    setLoading(false);
    return result;
  }, [addAxiosInterceptor]);

  const signIn = useCallback(
    async (email, password, rememberMe) => {
      const result = await sendSignInRequest(email, password);

      if (result.isOk) {
        setUser(result.data);
        setToken(result.token);

        removeAxiosInterceptor(); // Ensure previous interceptor is removed
        addAxiosInterceptor(result.token);

        if (rememberMe) {
          localStorage.setItem("blasterAuth", result.token);
        }
      }

      return result;
    },
    [addAxiosInterceptor, removeAxiosInterceptor]
  );

  const signOut = useCallback(() => {
    setUser(undefined);
    setToken(undefined);
    localStorage.removeItem("blasterAuth");

    removeAxiosInterceptor();
  }, [removeAxiosInterceptor]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisible(document.visibilityState === "visible");
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!currentUser) {
      signInWithToken();
    }
  }, [currentUser, signInWithToken]);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        signIn,
        signInWithToken,
        signOut,
        loading,
        visible,
      }}
      {...props}
    />
  );
};

export const useAuth = () => useContext(AuthContext);
