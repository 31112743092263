import http from "../services/httpService";
import config from "../config.json";

export async function signIn(email, password) {
  try {
    const params = JSON.stringify({
      email: email,
      password: password,
    });

    const response = await http.post(config.apiUrl + "/auth", params, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return {
      isOk: true,
      token: response.data.token,
      data: response.data.user,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "Authentication failed.",
    };
  }
}

export async function signInWithToken() {
  try {
    const response = await http.post(config.apiUrl + "/auth/loginWithToken");

    return {
      isOk: true,
      data: response.data.user,
    };
  } catch (error) {
    return {
      isOk: false,
      message: error,
    };
  }
}
