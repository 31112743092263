import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";

import {
  Chart,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
  Animation,
  ArgumentAxis,
  ValueAxis,
  Tick,
  Title,
  ZoomAndPan,
  Label,
  Series,
  Margin,
} from "devextreme-react/chart";

import { ExportExcel } from "../../../perfomanceData/excelExport";

const HoursChart = forwardRef((props, ref) => {
  const { dataSource, setShowReset, visualRangeV, visualRangeH } = props;

  const chartRef = useRef(null);

  const zoomStart = (e) => {
    if (e.actionType === "zoom") {
      setShowReset(true);
    }
  };

  const exportChart = useCallback((name) => {
    chartRef.current.instance.exportTo(name, "png");
  }, []);

  const exportExcel = async (data) => {
    data = data.map(({ Dummy, ...rest }) => rest);
    data.shift();
    ExportExcel(data, "DataExport", "DataExport");
  };

  useImperativeHandle(ref, () => ({
    // This will make `exportExcel` available to the parent component
    exportExcel,
    exportChart,
  }));

  const customizeText = (e) => `${e.value}`;
  return (
    <div className="paddingBottom">
      <Chart
        dataSource={dataSource}
        rotated={true}
        ref={chartRef}
        id="chart"
        onZoomStart={zoomStart}
      >
        <Title text={"Running Minutes"} subtitle="An Abbreviated Timeline" />
        <ArgumentAxis>
          <Label customizeText={customizeText} />
        </ArgumentAxis>

        <ValueAxis>
          <Tick visible={false} />
        </ValueAxis>

        <Series
          valueField="minutes"
          argumentField="date"
          type="bar"
          color="#79cac4"
        >
          <Label
            visible={true}
            backgroundColor="#c18e92"
            showForZeroValues={false}
          />
        </Series>

        <Legend visible={false} />
      </Chart>
    </div>
  );
});

export default HoursChart;
