import React from "react";
import "./style.css";
import { LoadPanel } from "devextreme-react/load-panel";
import "whatwg-fetch";
import reducer from "./reducer.js";
import { saveChange, loadUsers, setChanges, setEditRowKey } from "./actions.js";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Toolbar,
  Item,
  Lookup,
  Editing,
  Button as GridButton,
} from "devextreme-react/data-grid";

const initialState = {
  data: [],
  companies: [],
  changes: [],
  editRowKey: null,
  isLoading: false,
};

const loadPanelPosition = { of: "#gridContainer" };

export default function Users() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const changesText = React.useMemo(
    () =>
      JSON.stringify(
        state.changes.map((change) => ({
          type: change.type,
          key: change.type !== "insert" ? change.key : undefined,
          data: change.data,
        })),
        null,
        " "
      ),
    [state.changes]
  );

  React.useEffect(() => {
    loadUsers(dispatch);
  }, []);

  const onSaving = React.useCallback((e) => {
    e.cancel = true;
    e.promise = saveChange(dispatch, e.changes[0]);
  }, []);

  const onChangesChange = React.useCallback((changes) => {
    setChanges(dispatch, changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(dispatch, editRowKey);
  }, []);

  const permissionLevels = [
    { name: "Viewer", value: 1 },
    { name: "Editor", value: 128 },
    { name: "Admin", value: 2048 },
  ];

  return (
    <React.Fragment>
      <LoadPanel position={loadPanelPosition} visible={state.isLoading} />
      <DataGrid
        id="gridContainer"
        className={"dx-card wide-card"}
        keyExpr="id"
        dataSource={state.data}
        repaintChangesOnly
        showBorders={false}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        focusedRowEnabled={true}
        onSaving={onSaving}
      >
        <Editing
          mode="row"
          allowAdding
          allowDeleting
          allowUpdating
          onSaving={onSaving}
          changes={state.changes}
          onChangesChange={onChangesChange}
          editRowKey={state.editRowKey}
          onEditRowKeyChange={onEditRowKeyChange}
        />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        {/* <Column dataField={"id"} width={80} hidingPriority={7} /> */}
        <Column dataField={"name"} caption={"Name"} hidingPriority={6} />
        <Column dataField="companyId" caption="Company">
          <Lookup
            dataSource={state.companies}
            valueExpr="id"
            displayExpr="name"
          />
        </Column>
        <Column dataField={"email"} caption={"Email"} hidingPriority={4} />
        <Column
          dataField={"password"}
          caption={"Password"}
          hidingPriority={3}
        />

        <Column
          dataField="permissionLevel"
          caption="User Type"
          hidingPriority={2}
        >
          <Lookup
            dataSource={permissionLevels}
            valueExpr="value"
            displayExpr="name"
          />
        </Column>

        <Toolbar>
          <Item location="before">
            <h2 className="gridTitle">Users</h2>
          </Item>
          <Item name="addRowButton" showText="always" />
        </Toolbar>
      </DataGrid>
    </React.Fragment>
  );
}
