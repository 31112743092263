import React, { useCallback, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import LoadIndicator from "devextreme-react/load-indicator";

import PerformanceTime from "./performanceTime.js";

import DataController from "./dataController.js";

import {
  convertToUTCISO,
  convertDateToUTCISO,
} from "../../../helpers/reactHelper.js";

import "./performanceState.css";

import "devextreme/data/odata/store";
import DateRangeBox from "devextreme-react/date-range-box";

import { selectPerformanceDataQueryState } from "../../../analyticsData/analyticsDataSlice";

const PerformanceGroup = (props) => {
  const { group, currentMachine, allowUpdate, setAllowUpdate, i } = props;

  const AnalyticsChartRef = useRef(null);

  const pedrformanceQueryState = useSelector(selectPerformanceDataQueryState);

  const now = new Date();
  const previousDateStart = new Date(now);
  previousDateStart.setDate(now.getDate() - 1);

  const [reportDate, setReportDate] = useState([
    convertDateToUTCISO(previousDateStart),
    convertDateToUTCISO(previousDateStart),
  ]);

  const [showReset, setShowReset] = useState(false);

  const [visualRangeV, setVisualRangeV] = useState([null, null]);
  const [visualRangeH, setVisualRangeH] = useState([null, null]);
  const [dataReady, setDataReady] = useState(true);

  const [dataSource, setDataSource] = useState([{}]);

  const onCurrentValueChange = useCallback(
    ({ value: [startDate, endDate] }) => {
      const [sDate, eDate] = convertToUTCISO(startDate, endDate);
      if (sDate && eDate) {
        setReportDate([sDate, eDate]);
      }
    },
    []
  );

  useEffect(() => {
    onCurrentValueChange({ value: [previousDateStart, previousDateStart] });
  }, []);

  const sortedConfigs = currentMachine.machineConfigs.sort((a, b) => {
    if (a.configPort < b.configPort) return -1;
    if (a.configPort > b.configPort) return 1;
    return 0;
  });

  const updateChartData = useCallback(
    (data) => {
      setDataSource(data);
    },
    [setDataSource] // Add setDataSource as a dependency
  );

  const resetZoom = useCallback(
    (chartRef) => {
      setVisualRangeV([null, null]);
      setVisualRangeH([null, null]);
      setShowReset(false);
    },
    [setVisualRangeV, setVisualRangeH, setShowReset] // Add the necessary dependencies
  );

  return (
    <div>
      <div className="chart chartBorder">
        <div className="controller">
          <>
            <DataController
              sortedConfigs={sortedConfigs}
              updateChartData={updateChartData}
              currentMachine={currentMachine}
              reportDate={reportDate}
              setDataReady={setDataReady}
            ></DataController>

            <Toolbar className={"header-toolbar2"}>
              {i === 0 ? (
                <Item location={"before"} locateInMenu={"auto"}>
                  <div className="dx-field-value">
                    <DateRangeBox
                      defaultValue={reportDate}
                      onValueChanged={onCurrentValueChange}
                      displayFormat="dd MMM yyyy"
                      applyValueMode="useButtons"
                    />
                  </div>
                </Item>
              ) : (
                <></>
              )}

              {showReset ? (
                <Item location={"after"} locateInMenu={"auto"}>
                  <Button stylingMode={"text"}>
                    <Button text="Reset Zoom" onClick={() => resetZoom()} />
                  </Button>
                </Item>
              ) : (
                <></>
              )}
              <Item location={"after"} locateInMenu={"auto"}>
                <Button stylingMode={"text"}>
                  <Button
                    text="Export Image"
                    onClick={() =>
                      currentMachine &&
                      AnalyticsChartRef.current.exportChart(
                        currentMachine.machineName
                      )
                    }
                  />
                  <Button
                    text="Export Data"
                    onClick={() =>
                      dataSource &&
                      AnalyticsChartRef.current.exportExcel(dataSource)
                    }
                  />
                </Button>
              </Item>
            </Toolbar>
          </>
        </div>

        {pedrformanceQueryState === "idle" && dataReady === true ? (
          dataSource?.length < 1 ? (
            <div className="progressIndicatorContainer">
              <div className="centeredProgressIndicator">NO DATA</div>
            </div>
          ) : (
            <PerformanceTime
              group={group}
              visualRangeV={visualRangeV}
              visualRangeH={visualRangeH}
              ref={AnalyticsChartRef}
              dataSource={dataSource}
              setShowReset={setShowReset}
              currentMachine={currentMachine}
            ></PerformanceTime>
          )
        ) : (
          <div className="progressIndicatorContainer">
            <div className="centeredProgressIndicator">
              <LoadIndicator width={"24px"} height={"24px"} visible={true} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PerformanceGroup;
