import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export async function ExportExcel(excelData, fileName, sheetName, cols2remove) {
  const mydata = excelData.map(
    ({ sDate, eDate, index, ...keepAttrs }) => keepAttrs
  );

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(mydata);
    const wb = { Sheets: { data: ws }, SheetName: ["data"] };

    const excelBuffer = XLSX.write(
      {
        Sheets: { data: ws },
        SheetNames: ["data"],
      },
      { bookType: "xlsx", type: "array" }
    );

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  exportExcel();
}
