import React, { useEffect, useState } from "react";
import {
  CircularGauge,
  Scale,
  Label,
  RangeContainer,
  Range,
  Title,
  Font,
  Export,
  Margin,
  Subtitle,
} from "devextreme-react/circular-gauge";
import Grid from "@mui/material/Grid"; // MUI Grid

const RenderCustomItem = (props) => {
  const { id, title, value, config } = props;

  const [goodOrBad, setGoodOrBad] = useState("good");
  const [formattedValue, setFormattedValue] = useState("0.00");

  useEffect(() => {
    if (value <= config.configMinMarginal) {
      setGoodOrBad("bad");
    } else if (value < config.configMinDesired) {
      setGoodOrBad("marginal");
    } else if (value < config.configMaxDesired) {
      setGoodOrBad("good");
    } else if (value < config.configMaxMarginal) {
      setGoodOrBad("marginal");
    } else if (value < config.configMax) {
      setGoodOrBad("bad");
    } else {
      setGoodOrBad("bad");
    }

    if (value !== undefined) {
      setFormattedValue(value.toFixed(2)); // Rounds to 2 decimal places and converts to a string
    }
  }, [value, config]);

  return (
    <React.Fragment>
      <CircularGauge id={id} value={value}>
        <Margin top={0} bottom={0} left={0} right={0} />
        <Scale
          startValue={config.configMin}
          endValue={config.configMax}
          tickInterval={config.tickInterval}
        >
          <Label useRangeColors={true} />
        </Scale>
        <RangeContainer>
          <Range
            startValue={config.configMin}
            endValue={config.configMinMarginal}
            color="Red"
          />
          <Range
            startValue={config.configMinMarginal}
            endValue={config.configMinDesired}
            color="Orange"
          />
          <Range
            startValue={config.configMinDesired}
            endValue={config.configMaxDesired}
            color="Green"
          />
          <Range
            startValue={config.configMaxDesired}
            endValue={config.configMaxMarginal}
            color="Orange"
          />
          <Range
            startValue={config.configMaxMarginal}
            endValue={config.configMax}
            color="Red"
          />
        </RangeContainer>
        <Title text={title}>
          <Font size={16} />
          <Subtitle text={(config.rcCount / 60 / 60).toFixed(2) + " hrs"}>
            <Font size={10} />
          </Subtitle>
        </Title>
        <Export enabled={false} />
      </CircularGauge>
      <span className={goodOrBad}>{formattedValue} Amps</span>
    </React.Fragment>
  );
};

const CurrentValues = (props) => {
  const { currentMachine, group, currentData } = props;

  const filteredConfigs = currentMachine.machineConfigs
    .filter((g) => g.configGroup === group)
    .filter((i) => i.inGauge === 1);

  const gaugeCount = filteredConfigs.length;

  const calculateGridSizes = () => {
    if (gaugeCount <= 0) return { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };

    // Determine grid size for small and medium screens
    const sm_md = gaugeCount <= 3 ? 12 / gaugeCount : 4;

    // Determine grid size for large screens
    const lg = gaugeCount <= 12 ? 12 / gaugeCount : 1;

    return { xs: 12, sm: sm_md, md: sm_md, lg, xl: lg };
  };

  const gridSizes = calculateGridSizes();

  return (
    <React.Fragment>
      <Grid container spacing={3} justifyContent="center">
        {filteredConfigs.map((config, i) => (
          <Grid
            key={i}
            item
            xs={gridSizes.xs}
            sm={gridSizes.sm}
            md={gridSizes.md}
            lg={gridSizes.lg}
            xl={gridSizes.xl}
            className="blastWheelGauge"
          >
            <RenderCustomItem
              id={config.id}
              title={config.configName}
              value={currentData[config.configName]}
              config={config}
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default CurrentValues;
