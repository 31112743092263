import React, { useState } from "react";
import "./currentViewer.scss";
import Form from "devextreme-react/form";
import { useLocation } from "react-router-dom";
import { Connector } from "../../mqtt-react-hooks/index";

import Current from "../current/current";
import config from "../../config.json";

export default function CurrentViewer() {
  let location = useLocation();
  let page = location.state?.itemData?.page;

  const mqttoptions = {
    username: "centriblast",
    password: "CentPass2023",
    keepalive: 60,
    clientId: "mqttjs2_" + Math.random().toString(16).substr(2, 8),
    clean: true,
    resubscribe: true,
  };

  return (
    <Connector
      key={"Connector"}
      brokerUrl={config.brokerUrl}
      options={mqttoptions}
    >
      <React.Fragment key={"F1"}>
        <h2 className={"content-block"}>{page.pageTitle}</h2>

        {page.Machines.map((machine) => (
          <React.Fragment key={"machine-fragment-" + machine.id}>
            <h2 key={"H2" + machine.id} className={"content-block"}>
              {machine.machineName}
            </h2>
            <div
              key={"d" + machine.id}
              className={"content-block dx-card responsive-paddings"}
            >
              <Current
                key={"Current" + machine.id}
                currentMachine={machine}
              ></Current>
            </div>
          </React.Fragment>
        ))}
      </React.Fragment>
    </Connector>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};
