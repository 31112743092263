import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

export const useEffectAsync = (effect, deps) => {
  const ref = useRef();
  useEffect(() => {
    effect().then((result) => (ref.current = result));
    return () => {
      const result = ref.current;
      if (result) {
        result();
      }
    };
  }, deps);
};

export function isValidDate(dateStr) {
  const date = new Date(dateStr);
  return !isNaN(date.getTime());
}
export function convertToUTCISO(startDate, endDate) {
  if (isValidDate(startDate) && isValidDate(endDate)) {
    var sDate = new Date(
      Date.UTC(
        new Date(startDate).getFullYear(),
        new Date(startDate).getMonth(),
        new Date(startDate).getDate(),
        0,
        0,
        0,
        0
      )
    );
    var eDate = new Date(
      Date.UTC(
        new Date(endDate).getFullYear(),
        new Date(endDate).getMonth(),
        new Date(endDate).getDate(),
        23,
        59,
        59,
        999
      )
    );
    sDate = sDate.toISOString();
    eDate = eDate.toISOString();
    return [sDate, eDate];
  }
  return [null, null];
}

export function convertDateToUTCISO(date) {
  if (isValidDate(date)) {
    var sDate = new Date(
      Date.UTC(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0
      )
    );
    sDate = sDate.toISOString();
    return sDate;
  }
  return null;
}
