import React from "react";
import { LoadPanel } from "devextreme-react/load-panel";
import "whatwg-fetch";
import reducer from "./reducer.js";
import "./style.css";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Toolbar,
  Item,
  Editing,
  Button as GridButton,
} from "devextreme-react/data-grid";

import {
  saveChange,
  loadCustomers,
  setChanges,
  setEditRowKey,
} from "./actions.js";

const initialState = {
  data: [],
  changes: [],
  editRowKey: null,
  isLoading: false,
};

const loadPanelPosition = { of: "#gridContainer" };

export default function Customers() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  // const changesText = React.useMemo(
  //   () =>
  //     JSON.stringify(
  //       state.changes.map((change) => ({
  //         type: change.type,
  //         key: change.type !== "insert" ? change.key : undefined,
  //         data: change.data,
  //       })),
  //       null,
  //       " "
  //     ),
  //   [state.changes]
  // );

  React.useEffect(() => {
    loadCustomers(dispatch);
  }, []);

  const onSaving = React.useCallback((e) => {
    e.cancel = true;
    e.promise = saveChange(dispatch, e.changes[0]);
  }, []);

  const onChangesChange = React.useCallback((changes) => {
    setChanges(dispatch, changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(dispatch, editRowKey);
  }, []);

  return (
    <React.Fragment>
      <LoadPanel position={loadPanelPosition} visible={state.isLoading} />
      <DataGrid
        id="gridContainer"
        className={"dx-card wide-card"}
        keyExpr="id"
        dataSource={state.data}
        repaintChangesOnly
        showBorders={false}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        focusedRowEnabled={true}
        onSaving={onSaving}
      >
        <Editing
          mode="row"
          allowAdding
          allowDeleting
          allowUpdating
          onSaving={onSaving}
          changes={state.changes}
          onChangesChange={onChangesChange}
          editRowKey={state.editRowKey}
          onEditRowKeyChange={onEditRowKeyChange}
        />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        {/* <Column dataField={"id"} width={80} hidingPriority={7} /> */}
        <Column dataField={"name"} caption={"Name"} hidingPriority={6} />
        <Column
          dataField={"address1"}
          caption={"Address1"}
          hidingPriority={5}
        />
        <Column
          dataField={"address2"}
          caption={"Address2"}
          hidingPriority={4}
        />
        <Column dataField={"city"} caption={"City"} hidingPriority={3} />
        <Column dataField={"postCode"} hidingPriority={2} />

        <Toolbar>
          <Item location="before">
            <h2 className="gridTitle">Customers</h2>
          </Item>
          <Item name="addRowButton" showText="always" />
        </Toolbar>
      </DataGrid>
    </React.Fragment>
  );
}
