import React, { useEffect, useState } from "react";

import "./home.scss";
import Form from "devextreme-react/form";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../login/loginSlice";
import {
  getMachineDataAsync,
  getMachineLogAsync,
  selectMachineData,
  selectMachineStatus,
  selectMachineError,
  selectLogData,
  selectLogStatus,
  selectLogError,
} from "../../machineData/machineDataSlice";
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import MachineState from "./machineState";

import errorIcon from "../../assets/error48.png";
import warningIcon from "../../assets/warning48.png";
import naIcon from "../../assets/na48.png";
import offIcon from "../../assets/off48.png";
import okIcon from "../../assets/ok48.png";

export default function Home() {
  const dispatch = useDispatch();

  const [selectedMachine, setSelectedMachine] = useState(null);

  const currentUser = useSelector(selectCurrentUser);

  const machineData = useSelector(selectMachineData);
  const logData = useSelector(selectLogData);

  const machineStatus = useSelector(selectMachineStatus);
  const logStatus = useSelector(selectLogStatus);

  const machineError = useSelector(selectMachineError);
  const logError = useSelector(selectMachineError);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        await dispatch(getMachineDataAsync()).unwrap();
        if (selectedMachine !== null) {
          await dispatch(getMachineLogAsync(selectedMachine)).unwrap();
        }

        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
      } catch (err) {
        console.error("Failed to fetch machine data: ", err);
      }
    };

    if (currentUser) {
      fetchData();

      // Set up interval to fetch data every 10 seconds
      intervalId = setInterval(() => {
        fetchData();
      }, 5000);
    }

    // Clear interval on component unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentUser, dispatch, isInitialLoad, selectedMachine]);

  const highlightMachine = (machineId) => {
    setSelectedMachine(machineId);
  };

  const formatDate = (mysqlDate) => {
    try {
      // Replace space with 'T' to make it ISO 8601 compatible
      const isoDate = mysqlDate.replace(" ", "T");
      // Create a new Date object
      const date = new Date(isoDate);

      // Define options for toLocaleString
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24-hour format
      };

      // Format to dd/MM/yyyy HH:mm:ss
      return date.toLocaleString("en-GB", options).replace(",", "");
    } catch (error) {
      return "Error";
    }
  };

  const formatNumber = (data) => {
    try {
      return data.Value.toFixed(1); // Format number to 1 decimal place
    } catch (error) {
      return error.message;
    }
  };

  const formatStatus = (data) => {
    const { IsError, Value, Name } = data;

    const imHeight = 5;

    if (IsError === 0) {
      return Value === 0 ? (
        <img src={offIcon} alt="Off" className="off-icon-small" />
      ) : (
        <img
          height={imHeight}
          width={imHeight}
          src={okIcon}
          alt="Ok"
          className="ok-icon-small"
        />
      );
    } else if (IsError === 1) {
      if (Name === "lowOrange" || Name === "highOrange") {
        return (
          <img
            height={imHeight}
            width={imHeight}
            src={warningIcon}
            alt="Warning"
            className="error-icon-small"
          />
        );
      } else {
        return (
          <img
            height={imHeight}
            width={imHeight}
            src={errorIcon}
            alt="Error"
            className="error-icon-small"
          />
        );
      }
    } else {
      return (
        <img
          height={imHeight}
          width={imHeight}
          src={naIcon}
          alt="Not Available"
          className="na-icon-small"
        />
      );
    }
  };

  const dateRender = (data) => formatDate(data.value);
  const statusRender = (data) => formatStatus(data.data);
  const numberRender = (data) => formatNumber(data.data);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Home Page</h2>
      {isInitialLoad && machineStatus === "loading" && (
        <div className={"content-block dx-card responsive-paddings"}>
          <p>Loading machine data...</p>
        </div>
      )}
      {machineStatus === "failed" && (
        <div className={"content-block dx-card responsive-paddings"}>
          <p>Error loading machine data: {machineError}</p>
        </div>
      )}

      {machineData.length > 0 ? (
        <div className={"content-block dx-card responsive-paddings"}>
          {machineData.map((machine, index) => (
            <MachineState
              key={index}
              machine={machine}
              selectedMachine={selectedMachine}
              highlightMachine={highlightMachine}
            ></MachineState>
          ))}
        </div>
      ) : (
        <></>
      )}

      <div className={"content-block dx-card responsive-paddings"}>
        <DataGrid
          dataSource={logData}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          showBorders={true}
          width="100%"
        >
          <GroupPanel visible={true} />
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <Grouping autoExpandAll={false} />

          <Column
            name="logoStatus"
            dataField="Name"
            caption="Name"
            alignment="left"
            cellRender={statusRender}
          />

          <Column
            dataField="DateTime"
            caption="Date Time"
            alignment="left"
            cellRender={dateRender}
          />

          <Column
            dataField="ConfigPort"
            caption="Config Port"
            alignment="left"
          />

          <Column
            dataField="ConfigName"
            caption="Config Name"
            alignment="left"
          />

          <Column
            dataField="ConfigGroup"
            caption="Config Group"
            alignment="left"
          />

          <Column
            name="bareStatus"
            dataField="Name"
            caption="Status"
            alignment="left"
          />
          <Column
            dataField="Value"
            caption="Value"
            alignment="left"
            cellRender={numberRender}
          />

          <Pager showPageSizeSelector={false} />
          <Paging defaultPageSize={5} />
        </DataGrid>
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};
