import {
  HomePage,
  CurrentViewer,
  AnalyticsViewer,
  TasksPage,
  ProfilePage,
  CustomersPage,
  UsersPage,
  MachinesPage,
  PagesPage,
} from "./pages";

import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/tasks",
    element: TasksPage,
  },
  {
    path: "/currentViewer",
    element: CurrentViewer,
  },
  {
    path: "/analyticsViewer",
    element: AnalyticsViewer,
  },
  {
    path: "/customers",
    element: CustomersPage,
  },
  {
    path: "/users",
    element: UsersPage,
  },
  {
    path: "/machines",
    element: MachinesPage,
  },
  {
    path: "/pages",
    element: PagesPage,
  },
  {
    path: "/home",
    element: HomePage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
