import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "./blasterDataAPI";
import moment from "moment";

import { createSelector } from "reselect";

const getComponents = (state) => state.blasterData.components;
const getComponentId = (_, componentId) => componentId;

const initialState = {
  components: {}, // Each component's state will be stored here by its ID
};

const aSecond = 1000; // 1000 milliseconds in a second
const anHour = 60 * 60 * 1000; // 60 seconds in a minute, 60 minutes in an hour, 1000 milliseconds in a second

function addOneSecondToDateString(dateString) {
  // Create a Date object directly from the dateString

  const parts = dateString.split(/[- :.]/);
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[2], 10);
  const hour = parseInt(parts[3], 10);
  const minute = parseInt(parts[4], 10);
  const second = parseInt(parts[5], 10);
  const millisecond = parseInt(parts[6], 10);

  // Create a new Date object with correct timezone offset
  const originalDate = new Date(
    Date.UTC(year, month, day, hour, minute, second, millisecond)
  );

  // Add 1 second to the Date object
  originalDate.setSeconds(originalDate.getSeconds() + 1);

  // Return the formatted date string using toISOString and replace methods for formatting
  return (
    originalDate
      .toISOString()
      .replace(/T/, " ")
      .replace(/\..+/, "")
      .replace(/-/g, "-")
      .replace(/:/g, ":") +
    "." +
    originalDate.getMilliseconds().toString().padStart(3, "0")
  );
}

// const simpleTimeFrames = [
//   { id: 1, timeFrame: "1 Hour" },
//   { id: 2, timeFrame: "4 Hours" },
//   { id: 3, timeFrame: "12 Hours" },
//   { id: 4, timeFrame: "24 Hours" },
//   { id: 5, timeFrame: "1 Week" },
// ];

export const getBlasterDataAsync = createAsyncThunk(
  "blasterData/getBlasterData",
  async ({ componentId, machineId, timeframe }) => {
    const response = await getData(machineId, timeframe);

    return { componentId, data: response.data };
  }
);

export const blasterDataSlice = createSlice({
  name: "blasterData",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearData: (state, action) => {
      const { componentId } = action.payload;
      //("clearData");
      if (state.components[componentId]) {
        state.components[componentId].performanceData = [];
      }
    },
    addData: (state, action) => {
      const { componentId, message: blasterlineJson } = action.payload;
      //console.log("addData componentId", componentId);
      //console.log("blasterlineJson", blasterlineJson);
      // Reference to the specific component's state for easier manipulation
      const componentState = state.components[componentId];

      if (componentState.status !== "idle") {
        return;
      }

      const timespan = anHour * componentState.currentTimeFrame;

      componentState.blockDummy = true;

      const curMachine = componentState.currentMachine;
      const blasterline = JSON.parse(blasterlineJson);

      const transcribedBlasterLine = { DateTime: blasterline.DateTime };

      curMachine.machineConfigs.forEach((config) => {
        if (blasterline.hasOwnProperty(config.configPort)) {
          transcribedBlasterLine[config.configName] =
            blasterline[config.configPort] * config.VA_Multiplier;
        } else {
          transcribedBlasterLine[config.configName] = 0;
        }
      });

      componentState.latestData = transcribedBlasterLine;
      componentState.performanceData.push(transcribedBlasterLine);

      while (true) {
        const latestDateTime = new Date(componentState.latestData.DateTime);
        const firstDateTime = new Date(
          componentState.performanceData[0].DateTime
        );
        // Ensure there's a next data point to compare
        if (componentState.performanceData.length > 1) {
          const nextDateTime = new Date(
            componentState.performanceData[1].DateTime
          );

          if (latestDateTime - firstDateTime > timespan) {
            if (nextDateTime - firstDateTime > aSecond) {
              componentState.performanceData[0].DateTime =
                addOneSecondToDateString(
                  componentState.performanceData[0].DateTime
                );
            } else {
              componentState.performanceData.shift();
            }
          } else {
            break;
          }
        } else {
          // Break the loop if there's not enough data to proceed
          break;
        }
      }
    },
    addDummy: (state, action) => {
      const componentId = action.payload;

      const timespan = anHour * state.components[componentId].currentTimeFrame;

      if (state.components[componentId].status !== "idle") {
        return;
      }

      if (state.components[componentId].blockDummy) {
        state.components[componentId].blockDummy = false;
        return;
      }

      const latestDummy = Object.assign(
        state.components[componentId].latestData
      );

      latestDummy.DateTime = addOneSecondToDateString(latestDummy.DateTime);
      latestDummy.Dummy = true;

      state.components[componentId].latestData = latestDummy;
      state.components[componentId].performanceData.push(latestDummy);

      while (true) {
        const latestDateTime = new Date(
          state.components[componentId].latestData.DateTime
        );
        const firstDateTime = new Date(
          state.components[componentId].performanceData[0].DateTime
        );
        const nextDateTime = new Date(
          state.components[componentId].performanceData[1].DateTime
        );

        if (latestDateTime - firstDateTime > timespan) {
          if (nextDateTime - firstDateTime > aSecond) {
            state.components[componentId].performanceData[0].DateTime =
              addOneSecondToDateString(
                state.components[componentId].performanceData[0].DateTime
              );
            //console.log("ALTER DUMMY", state.performanceData[0].DateTime);
          } else {
            state.components[componentId].performanceData.shift();
            //console.log("SHIFT DUMMY", state.performanceData[0].DateTime);
          }
        } else {
          break;
        }
      }
    },
    setCurrentMachine: (state, action) => {
      // console.log("setCurrentMachine");
      const { componentId, machine } = action.payload;

      if (!state.components[componentId]) {
        state.components[componentId] = {
          status: "idle",
          currentMachine: machine,
          currentTimeFrame: 1,
          latestData: {},
          lastDummyData: {},
          blockDummy: false,
          performanceData: [],
        };
      }
    },
    setCurrentTimeFrame: (state, action) => {
      //console.log("setCurrentTimeFrame");
      const { componentId, timeFrame } = action.payload;

      if (state.components[componentId]) {
        state.components[componentId].currentTimeFrame = timeFrame;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlasterDataAsync.pending, (state, action) => {
        const { componentId } = action.meta.arg;
        //console.log("getBlasterDataAsync.pending");
        state.components[componentId].status = "loading";
      })
      .addCase(getBlasterDataAsync.fulfilled, (state, action) => {
        //console.log("getBlasterDataAsync.fulfilled");
        const { componentId } = action.payload;

        const curMachine = state.components[componentId].currentMachine;

        const data = action.payload.data.result;

        if (!state.components[componentId]) {
          state.components[componentId] = {
            status: "pending",
            currentMachine: null,
            currentTimeFrame: 1,
            latestData: {},
            lastDummyData: {},
            blockDummy: false,
            performanceData: [],
          };
        }

        const returnData = data.map((obj) => {
          const newObj = { DateTime: obj.DateTime };

          curMachine.machineConfigs.map((config) => {
            if (obj.hasOwnProperty(config.configPort)) {
              newObj[config.configName] =
                obj[config.configPort] * config.VA_Multiplier;
            } else {
              newObj[config.configName] = 0;
            }
          });

          return newObj;
        });

        state.components[componentId].performanceData = returnData;

        if (state.components[componentId].performanceData.length > 0) {
          state.components[componentId].latestData =
            state.components[componentId].performanceData[
              state.components[componentId].performanceData.length - 1
            ];
        }
        state.components[componentId].status = "idle";
      });
  },
});

export const {
  clearData,
  addData,
  addDummy,
  setCurrentMachine,
  setCurrentTimeFrame,
} = blasterDataSlice.actions;

export const selectBlasterData = createSelector(
  [getComponents, getComponentId],
  (components, componentId) => components[componentId]?.performanceData || []
);

export const selectLatestData = createSelector(
  [getComponents, getComponentId],
  (components, componentId) => components[componentId]?.latestData || {}
);

export const selectBlasterDataQueryState = createSelector(
  [getComponents, getComponentId],
  (components, componentId) => components[componentId]?.status || "idle"
);

export const selectCurrentMachine = createSelector(
  [getComponents, getComponentId],
  (components, componentId) => components[componentId]?.currentMachine
);

export const selectCurrentTimeFrame = createSelector(
  [getComponents, getComponentId],
  (components, componentId) => components[componentId]?.currentTimeFrame || 1
);

export default blasterDataSlice.reducer;
