import { useSelector } from "react-redux";
import { selectCurrentUser } from "./login/loginSlice";

import { selectPages } from "./login/loginSlice";

const Navigation = () => {
  const currentUser = useSelector(selectCurrentUser);
  const pages = useSelector(selectPages);

  let nextKey = 0;
  // Define your navigation data as an array of objects
  const navigationDetails = [
    {
      text: "Home",
      path: "/home",
      icon: "home",
      key: nextKey,
    },
  ];

  if (pages && pages.length > 0) {
    pages.forEach((element) => {
      nextKey = nextKey + 4;
      navigationDetails.push({
        text: element.pageTitle,
        key: nextKey,
        icon: "chart",
        items: [
          {
            text: "Live Feed",
            path: "/currentViewer",
            admin: true,
            key: nextKey + 1,
            page: element,
          },
          {
            text: "Analytics",
            path: "/analyticsViewer",
            admin: true,
            key: nextKey + 3,
            page: element,
          },
        ],
      });
    });
  }

  if (currentUser?.permissionLevel === 2048) {
    nextKey = nextKey + 3;
    navigationDetails.push({
      text: "Admin",
      icon: "folder",
      admin: true,
      key: nextKey + 1,
      items: [
        {
          text: "Customers",
          path: "/customers",
          admin: true,
          key: nextKey + 2,
        },
        {
          text: "Users",
          path: "/users",
          admin: true,
          key: nextKey + 3,
        },
        {
          text: "Pages",
          path: "/pages",
          admin: true,
          key: nextKey + 4,
        },
        {
          text: "Machines",
          path: "/machines",
          admin: true,
          key: nextKey + 5,
        },
      ],
    });
  }

  return navigationDetails;
};

export default Navigation;
