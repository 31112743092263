import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import EfficiencyOverTime from "./EfficiencyOverTime";
import { SelectBox } from "devextreme-react/select-box";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";

import {
  setCurrentMachine,
  setCurrentTimeFrame,
  selectCurrentTimeFrame,
} from "../../perfomanceData/blasterDataSlice";

const EfficiencyGroup = (props) => {
  const { componentId, currentMachine, group, blasterData } = props;

  const efficiencyOverTimeRef = useRef(null);

  const dispatch = useDispatch();

  const [allowUpdate, setAllowUpdate] = useState(true);

  const simpleTimeFrames = [
    { id: 1, timeFrame: "1 Hour" },
    { id: 4, timeFrame: "4 Hours" },
    { id: 12, timeFrame: "12 Hours" },
    { id: 24, timeFrame: "24 Hours" },
    { id: 72, timeFrame: "72 Hours" },
  ];

  const currentTimeFrame = useSelector((state) =>
    selectCurrentTimeFrame(state, componentId)
  );

  const toggleAllowUpdate = (value) => {
    setAllowUpdate(value);
  };

  const resetZoom = () => {
    toggleAllowUpdate(true);
  };

  const timeFrameChanged = (selectedTimeFrame) => {
    dispatch(
      setCurrentTimeFrame({ componentId, timeFrame: selectedTimeFrame })
    );
  };

  return (
    <>
      <Toolbar className={"header-toolbar2"}>
        <Item location={"before"} locateInMenu={"auto"}>
          <SelectBox
            className={"timeframe-select"}
            label="Time Frame"
            labelMode="floating"
            items={simpleTimeFrames}
            value={currentTimeFrame}
            onValueChange={timeFrameChanged}
            valueExpr="id"
            displayExpr="timeFrame"
            height={"100%"}
            width={310}
          ></SelectBox>
        </Item>

        {!allowUpdate ? (
          <Item location={"after"} locateInMenu={"auto"}>
            <Button stylingMode={"text"}>
              <Button text="Reset Zoom" onClick={() => resetZoom()} />
            </Button>
          </Item>
        ) : (
          <></>
        )}
        <Item location={"after"} locateInMenu={"auto"}>
          <Button stylingMode={"text"}>
            <Button
              text="Export Image"
              onClick={() =>
                currentMachine &&
                efficiencyOverTimeRef.current.exportChart(
                  currentMachine.machineName
                )
              }
            />
            <Button
              text="Export Data"
              onClick={() =>
                blasterData &&
                efficiencyOverTimeRef.current.exportExcel(blasterData)
              }
            />
          </Button>
        </Item>
      </Toolbar>
      <EfficiencyOverTime
        ref={efficiencyOverTimeRef}
        currentMachine={currentMachine}
        group={group}
        lineData={blasterData}
        allowUpdate={allowUpdate}
        toggleAllowUpdate={toggleAllowUpdate}
      ></EfficiencyOverTime>
    </>
  );
};

export default EfficiencyGroup;
