import React, { useState } from "react";
import "./style.css";
import { LoadPanel } from "devextreme-react/load-panel";
import "whatwg-fetch";
import reducer from "./reducer.js";
import {
  saveChange,
  loadMachines,
  setChanges,
  setEditRowKey,
} from "./actions.js";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Toolbar,
  Item,
  Lookup,
  Editing,
  Button as GridButton,
  MasterDetail,
} from "devextreme-react/data-grid";

import ConfigDetailView from "./configDetailView";
import { Button } from "devextreme-react";

const initialState = {
  data: [],
  companies: [],
  pages: [],
  changes: [],
  editRowKey: null,
  isLoading: false,
};

const loadPanelPosition = { of: "#gridContainer" };

export default function Machines() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const [pages, setPages] = useState([]);

  const changesText = React.useMemo(
    () =>
      JSON.stringify(
        state.changes.map((change) => ({
          type: change.type,
          key: change.type !== "insert" ? change.key : undefined,
          data: change.data,
        })),
        null,
        " "
      ),
    [state.changes]
  );

  React.useEffect(() => {
    loadMachines(dispatch);
  }, []);

  React.useEffect(() => {
    setPages(state.pages);
  }, [state.pages]);

  const onSaving = React.useCallback((e) => {
    e.cancel = true;
    e.promise = saveChange(dispatch, e.changes[0]);
  }, []);

  const onChangesChange = React.useCallback((changes) => {
    setChanges(dispatch, changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(dispatch, editRowKey);
  }, []);

  const permissionLevels = [
    { name: "Viewer", value: 1 },
    { name: "Editor", value: 128 },
    { name: "Admin", value: 2048 },
  ];

  const getFilteredPages = (options) => ({
    store: pages,
    filter: options.data ? ["companyId", "=", options.data.companyId] : null,
  });

  function setPageValue(newData, value, currentRowData) {
    newData.companyId = value; // Assuming you are setting the StateID
    newData.pageId = null; // Additional logic to set CityID to null
  }

  return (
    <React.Fragment>
      <LoadPanel position={loadPanelPosition} visible={state.isLoading} />
      <DataGrid
        id="gridContainer"
        className={"dx-card wide-card"}
        keyExpr="id"
        dataSource={state.data}
        repaintChangesOnly
        showBorders={false}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        focusedRowEnabled={true}
        onSaving={onSaving}
      >
        <Editing
          mode="row"
          allowAdding
          allowDeleting
          allowUpdating
          onSaving={onSaving}
          changes={state.changes}
          onChangesChange={onChangesChange}
          editRowKey={state.editRowKey}
          onEditRowKeyChange={onEditRowKeyChange}
        />

        <MasterDetail enabled={true} component={ConfigDetailView} />

        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        {/* <Column dataField={"id"} width={80} hidingPriority={7} /> */}
        <Column
          dataField={"machineName"}
          caption={"Machine Identifier"}
          hidingPriority={6}
        />
        <Column
          dataField="companyId"
          caption="Company"
          setCellValue={setPageValue}
        >
          <Lookup
            dataSource={state.companies}
            valueExpr="id"
            displayExpr="name"
          />
        </Column>
        <Column dataField="pageId" caption="Page">
          <Lookup
            dataSource={getFilteredPages}
            valueExpr="id"
            displayExpr="pageTitle"
          />
        </Column>
        <Column type="buttons">
          <GridButton name="edit"></GridButton>
          <GridButton name="delete"></GridButton>
          <GridButton hint="Download Config" icon="download"></GridButton>
        </Column>

        <Toolbar>
          <Item location="before">
            <h2 className="gridTitle">Machines</h2>
          </Item>
          <Item name="addRowButton" showText="always" />
        </Toolbar>
      </DataGrid>
    </React.Fragment>
  );
}
