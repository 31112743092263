import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlastingHoursDataAsync,
  selectBlastingsHoursData,
} from "../../../analyticsData/analyticsDataSlice";
import "./blastingHours.css";

const DataController = (props) => {
  const { updateChartData, machine, reportDate, setDataReady } = props;

  const dispatch = useDispatch();
  const analyticsData = useSelector(selectBlastingsHoursData);

  const [machineId] = useState(machine?.id);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [processed, setProcessed] = useState(null);

  // const startDateRef = useRef(startDate);
  // const endDateRef = useRef(endDate);

  useEffect(() => {
    setEndDate(null);
    setStartDate(reportDate[0]);
    setEndDate(reportDate[1]);
  }, [reportDate]);

  // useEffect(() => {
  //   startDateRef.current = startDate;
  //   endDateRef.current = endDate;
  // }, [startDate, endDate]);

  // Fetch new analytics data when dates or machineId change
  useEffect(() => {
    if (startDate && endDate && startDate !== endDate) {
      setDataReady(false); // Show loading state before fetching new data
      dispatch(getBlastingHoursDataAsync({ machineId, startDate, endDate }));
    }
  }, [dispatch, machineId, startDate, endDate, setDataReady]);

  // Process data once new analytics data is fetched
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (startDate && endDate && analyticsData) {
      setProcessed(analyticsData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsData]); // Only run when analyticsData changes

  // Update chart data and set data ready to true after processing
  useEffect(() => {
    if (processed) {
      updateChartData(processed);
      setDataReady(true);
    }
  }, [processed, updateChartData, setDataReady]);

  return (
    <div className="controllerInner">
      {/* <ul className="configUList">
        {sortedConfigs.map((config) => (
          <React.Fragment key={"config-fragment-" + config.id}>
            <li className="lineItem">
              <div className="dx-field">
                <div className="dx-field-label">{config.configName}</div>
                <div className="dx-field-value">
                  <CheckBox
                    className="checkbox"
                    defaultValue={false}
                    iconSize={20}
                  />
                </div>
              </div>
            </li>
          </React.Fragment>
        ))}
      </ul> */}
    </div>
  );
};

export default DataController;
