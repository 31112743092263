import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";

import {
  Chart,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
  Animation,
  ArgumentAxis,
  ValueAxis,
  Tick,
  Title,
  ZoomAndPan,
} from "devextreme-react/chart";

import { ExportExcel } from "../../../perfomanceData/excelExport";

const RunningTime = forwardRef((props, ref) => {
  const { dataSource, toggleAllowUpdate, visualRangeV, visualRangeH } = props;

  const chartRef = useRef(null);

  const zoomStart = (e) => {
    if (e.actionType === "zoom") {
      toggleAllowUpdate(false);
    }
  };

  const exportChart = useCallback((name) => {
    chartRef.current.instance.exportTo(name, "png");
  }, []);

  const exportExcel = async (data) => {
    data = data.map(({ Dummy, ...rest }) => rest);
    data.shift();
    ExportExcel(data, "DataExport", "DataExport");
  };

  useImperativeHandle(ref, () => ({
    // This will make `exportExcel` available to the parent component
    exportExcel,
    exportChart,
  }));

  return (
    <div>
      <Chart
        ref={chartRef}
        id="chart"
        dataSource={dataSource}
        barGroupPadding={0.2}
        rotated={true}
        onZoomStart={zoomStart}
      >
        <ZoomAndPan
          dragToZoom={true}
          allowMouseWheel={false}
          panKey="ctrl"
          argumentAxis="both"
          valueAxis="both"
        />
        <ArgumentAxis visualRange={visualRangeV}>
          <Tick visible={false} />
        </ArgumentAxis>

        <ValueAxis
          visualRange={visualRangeH}
          FormatString="MM/dd/yy HH:mm"
        ></ValueAxis>

        <Title text="Machine Running" subtitle="An Abbreviated Timeline" />
        <CommonSeriesSettings
          type="rangebar"
          argumentField="Device"
          rangeValue1Field="start"
          rangeValue2Field="end"
          barOverlapGroup="State"
          minBarSize={4}
        ></CommonSeriesSettings>

        <Legend verticalAlignment="bottom" horizontalAlignment="center">
          <Title text="Running State" />
        </Legend>
        <SeriesTemplate nameField="State" />
        <Animation enabled={true} />
      </Chart>
    </div>
  );
});

export default RunningTime;
