import React, { useCallback, useState, useRef } from "react";
import { useSelector } from "react-redux";

import PerformanceGroup from "./performanceGroup";

const PerformanceGroups = (props) => {
  const { machine, allowUpdate, setAllowUpdate } = props;

  return (
    <React.Fragment>
      {machine?.groups ? (
        machine.groups.map((group, i) => (
          <React.Fragment key={i}>
            <PerformanceGroup
              currentMachine={machine}
              group={group.configGroup}
              allowUpdate={allowUpdate}
              setAllowUpdate={setAllowUpdate}
              i={i}
            ></PerformanceGroup>
          </React.Fragment>
        ))
      ) : (
        <div className={"content-block dx-card responsive-paddings"}>
          <h2 className={"content-block"}>No monitor groups defined</h2>
        </div>
      )}
    </React.Fragment>
  );
};

export default PerformanceGroups;
