import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./analyticsViewer.scss";
import { useLocation } from "react-router-dom";

import MachineState from "./machineState/machineState";
import PerformanceGroups from "./performanceState/performanceGroups";
import BlastingHours from "./blastingHours/blastingHours";
import OnOffHours from "./onOffHours/onOffHours";

export default function AnalyticsViewer() {
  let location = useLocation();
  let page = location.state?.itemData?.page;

  const [allowUpdate, setAllowUpdate] = useState(true);

  return (
    <React.Fragment key={"F1"}>
      <h2 className={"content-block"}>{page.pageTitle + " Analytics"}</h2>

      {page.Machines.map((machine) => (
        <React.Fragment key={"machine-fragment-" + machine.id}>
          <h2 key={"H2" + machine.id} className={"content-block"}>
            {machine.machineName}
          </h2>
          <div
            key={"d" + machine.id}
            className={"content-block dx-card responsive-paddings"}
          >
            <BlastingHours machine={machine}></BlastingHours>
            <MachineState
              machine={machine}
              allowUpdate={allowUpdate}
              setAllowUpdate={setAllowUpdate}
            ></MachineState>
            <PerformanceGroups
              machine={machine}
              allowUpdate={allowUpdate}
              setAllowUpdate={setAllowUpdate}
            ></PerformanceGroups>
            <OnOffHours machine={machine}></OnOffHours>
          </div>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
