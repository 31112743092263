import React, { useCallback, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import LoadIndicator from "devextreme-react/load-indicator";

import RunningTime from "./runningTime.js";

import DataController from "./dataController.js";

import {
  convertToUTCISO,
  convertDateToUTCISO,
} from "../../../helpers/reactHelper.js";

import "./machineState.css";

import "devextreme/data/odata/store";
import DateRangeBox from "devextreme-react/date-range-box";

import { selectAnalyticsDataQueryState } from "../../../analyticsData/analyticsDataSlice";

const MachineState = (props) => {
  const { machine, allowUpdate, setAllowUpdate } = props;

  const AnalyticsChartRef = useRef(null);

  const analyticsQueryState = useSelector(selectAnalyticsDataQueryState);

  const now = new Date();
  const previousDateStart = new Date(now);
  previousDateStart.setDate(now.getDate() - 1);

  const [reportDate, setReportDate] = useState([
    convertDateToUTCISO(previousDateStart),
    convertDateToUTCISO(previousDateStart),
  ]);

  const [visualRangeV, setVisualRangeV] = useState([null, null]);
  const [visualRangeH, setVisualRangeH] = useState([null, null]);
  const [dataReady, setDataReady] = useState(true);

  const [dataSource, setDataSource] = useState([{}]);

  const onCurrentValueChange = useCallback(
    ({ value: [startDate, endDate] }) => {
      const [sDate, eDate] = convertToUTCISO(startDate, endDate);
      if (sDate && eDate) {
        setReportDate([sDate, eDate]);
      }
    },
    [setReportDate]
  );

  useEffect(() => {
    onCurrentValueChange({ value: [previousDateStart, previousDateStart] });
  }, []);

  const sortedConfigs = machine.machineConfigs.sort((a, b) => {
    if (a.configPort < b.configPort) return -1;
    if (a.configPort > b.configPort) return 1;
    return 0;
  });

  const updateChartData = useCallback(
    (data) => {
      setDataSource(data);
    },
    [setDataSource] // Add setDataSource as a dependency
  );

  const resetZoom = useCallback(
    (chartRef) => {
      setVisualRangeV([null, null]);
      setVisualRangeH([null, null]);
      setAllowUpdate(true);
    },
    [setVisualRangeV, setVisualRangeH, setAllowUpdate] // Add the necessary dependencies
  );

  const toggleAllowUpdate = useCallback(
    (value) => {
      setAllowUpdate(value);
    },
    [setAllowUpdate] // Add setAllowUpdate as a dependency
  );

  return (
    <div>
      <div className="chart chartBorder">
        <div className="controller ">
          <>
            <DataController
              sortedConfigs={sortedConfigs}
              updateChartData={updateChartData}
              machine={machine}
              reportDate={reportDate}
              setDataReady={setDataReady}
            ></DataController>

            <Toolbar className={"header-toolbar2"}>
              <Item location={"before"} locateInMenu={"auto"}>
                <div className="dx-field-value">
                  <DateRangeBox
                    defaultValue={reportDate}
                    onValueChanged={onCurrentValueChange}
                    displayFormat="dd MMM yyyy"
                    applyValueMode="useButtons"
                  />
                </div>
              </Item>

              {!allowUpdate ? (
                <Item location={"after"} locateInMenu={"auto"}>
                  <Button stylingMode={"text"}>
                    <Button text="Reset Zoom" onClick={() => resetZoom()} />
                  </Button>
                </Item>
              ) : (
                <></>
              )}
              <Item location={"after"} locateInMenu={"auto"}>
                <Button stylingMode={"text"}>
                  <Button
                    text="Export Image"
                    onClick={() =>
                      machine &&
                      AnalyticsChartRef.current.exportChart(machine.machineName)
                    }
                  />
                  <Button
                    text="Export Data"
                    onClick={() =>
                      dataSource &&
                      AnalyticsChartRef.current.exportExcel(dataSource)
                    }
                  />
                </Button>
              </Item>
            </Toolbar>
          </>
        </div>

        {analyticsQueryState === "idle" && dataReady === true ? (
          dataSource?.length < 1 ? (
            <div className="progressIndicatorContainer">
              <div className="centeredProgressIndicator">NO DATA</div>
            </div>
          ) : (
            <RunningTime
              visualRangeV={visualRangeV}
              visualRangeH={visualRangeH}
              ref={AnalyticsChartRef}
              dataSource={dataSource}
              toggleAllowUpdate={toggleAllowUpdate}
              allowUpdate={allowUpdate}
            ></RunningTime>
          )
        ) : (
          <div className="progressIndicatorContainer">
            <div className="centeredProgressIndicator">
              <LoadIndicator width={"24px"} height={"24px"} visible={true} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MachineState;
