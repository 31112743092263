import { configureStore, combineReducers } from "@reduxjs/toolkit";

import loginReducer from "../login/loginSlice";
import blasterDataReducer from "../perfomanceData/blasterDataSlice";
import machineDataReducer from "../machineData/machineDataSlice";
import analyticsDataReducer from "../analyticsData/analyticsDataSlice";

const combinedReducer = combineReducers({
  login: loginReducer,
  blasterData: blasterDataReducer,
  machineData: machineDataReducer,
  analyticsData: analyticsDataReducer,
});

export default configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
