import { useEffect } from "react";
import { useSubscription } from "../mqtt-react-hooks/index";
import { useDispatch } from "react-redux";

import { addData } from "../perfomanceData/blasterDataSlice";

const MQTTSubscriber = (props) => {
  const { machine_id, company_id } = props;

  const channel = company_id + "_" + machine_id;

  const dispatch = useDispatch();

  const { message } = useSubscription(channel); //need to change this to machineid TODO

  // useEffect(() => {
  //   console.log("SUBSCRIBE", channel);
  // }, []);

  useEffect(() => {
    if (message && message.message) {
      dispatch(addData({ componentId: machine_id, message: message.message }));
    }
  }, [message, dispatch, machine_id]);

  return null;
};

export default MQTTSubscriber;
