import http from "../services/httpService";
import config from "../config.json";

import { selectAccessToken } from "./../login/loginSlice";

export async function getData(machineId, timeframe) {
  const response = await http.get(
    config.apiUrl + `/data/` + machineId + "/" + timeframe
  );

  return response;
}
