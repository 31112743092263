import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import { SelectBox } from "devextreme-react/select-box";

// import { selectMachines } from "../../login/loginSlice";

// import { setCurrentMachine } from "../../perfomanceData/blasterDataSlice";

import UserPanel from "../user-panel/UserPanel";
import "./Header.scss";
import { useLocation } from "react-router-dom";

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  const dispatch = useDispatch();
  const location = useLocation();

  // const [selectedMachine, setSelectedMachine] = useState(null);

  // const machines = useSelector(selectMachines);

  // useEffect(() => {
  //   if (machines.length > 0) {
  //     setSelectedMachine(machines[0].id);
  //     dispatch(setCurrentMachine(machines[0]));
  //   } else {
  //     setSelectedMachine(null);
  //     dispatch(setCurrentMachine(null));
  //   }
  // }, [machines, dispatch]);

  // const machineChanged = (selectedId) => {
  //   setSelectedMachine(selectedId);

  //   const mach = machines.find((item) => item.id === selectedId);

  //   dispatch(setCurrentMachine(mach));
  // };

  return (
    <header className={"header-component"}>
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={"before"}
          cssClass={"header-title"}
          text={title}
          visible={!!title}
        />
        {/* {location?.pathname === "/home" ? (
          <Item location={"before"} locateInMenu={"auto"}>
            <SelectBox
              className={"machine-select"}
              label="Machine"
              labelMode="floating"
              dataSource={machines}
              value={selectedMachine}
              onValueChange={machineChanged}
              valueExpr="id"
              displayExpr="machineName"
              height={"100%"}
              width={310}
            ></SelectBox>
          </Item>
        ) : (
          <></>
        )} */}
        <Item location={"after"} locateInMenu={"auto"}>
          <Button className={"user-button authorization1"} stylingMode={"text"}>
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
      </Toolbar>
    </header>
  );
}
