import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "devextreme-react/button";
import {
  addData,
  selectBlasterDataQueryState,
  selectBlasterData,
  selectLatestData,
  selectCurrentMachine,
  selectCurrentTimeFrame,
  setCurrentMachine,
  getBlasterDataAsync,
  clearData,
} from "../../perfomanceData/blasterDataSlice";

import { useAuth } from "../../contexts/auth";

import LoadIndicator from "devextreme-react/load-indicator";
import AddDummyEntry from "../../perfomanceData/AddDummyEntry.js";

import Grid from "@mui/material/Grid"; // Grid version 1

import Box, { Item } from "devextreme-react/box";

import "./current.scss";
import MQTTStatus from "../../components/MQTTStatus.js";

import CurrentValues from "./CurrentValues";
import { selectAccessToken } from "../../login/loginSlice";

import MQTTSubscriber from "../../components/MQTTSubscriber";

import EfficiencyGroup from "./EfficiencyGroup";

export default function Current(props) {
  const { currentMachine } = props;

  const componentId = currentMachine.id;

  const dispatch = useDispatch();

  const { user, loading, visible } = useAuth();

  const currentTimeFrame = useSelector((state) =>
    selectCurrentTimeFrame(state, componentId)
  );
  const queryState = useSelector((state) =>
    selectBlasterDataQueryState(state, componentId)
  );
  const blasterData = useSelector((state) =>
    selectBlasterData(state, componentId)
  );
  const currentData = useSelector((state) =>
    selectLatestData(state, componentId)
  );

  useEffect(() => {
    dispatch(setCurrentMachine({ componentId, machine: currentMachine }));
  }, []);

  useEffect(() => {
    dispatch(clearData({ componentId }));

    if (currentMachine) {
      dispatch(
        getBlasterDataAsync({
          componentId: currentMachine?.id,
          machineId: currentMachine?.id,
          timeframe: currentTimeFrame,
        })
      );
    }
  }, [currentMachine, currentTimeFrame, visible]);

  return visible ? (
    <React.Fragment>
      {currentMachine ? (
        <MQTTSubscriber
          machine_id={currentMachine?.id}
          company_id={currentMachine?.companyId}
        />
      ) : (
        <></>
      )}

      <AddDummyEntry componentId={componentId} />

      {currentMachine?.groups ? (
        currentMachine.groups.map((group, i) => (
          <React.Fragment key={i}>
            <h2 className={"content-block"}>{group.configGroup}</h2>
            <div className={"content-block dx-card responsive-paddings "}>
              {queryState === "idle" ? (
                <EfficiencyGroup
                  componentId={componentId}
                  currentMachine={currentMachine}
                  group={group.configGroup}
                  blasterData={blasterData}
                ></EfficiencyGroup>
              ) : (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              )}
            </div>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <div className={"content-block dx-card responsive-paddings"}>
                  {queryState === "idle" ? (
                    <CurrentValues
                      currentMachine={currentMachine}
                      group={group.configGroup}
                      currentData={currentData}
                    ></CurrentValues>
                  ) : (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        ))
      ) : (
        <div className={"content-block dx-card responsive-paddings"}>
          <h2 className={"content-block"}>No monitor groups defined</h2>
        </div>
      )}
    </React.Fragment>
  ) : (
    <></>
  );
}
