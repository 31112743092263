import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  memo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setCurrentTimeFrame,
  selectCurrentTimeFrame,
} from "../../perfomanceData/blasterDataSlice";

import styles from "./chart.module.css";

import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  CommonAxisSettings,
  Grid,
  Export as ChartExport,
  Legend,
  Margin as ChartMargin,
  Tooltip,
  Label as ChartLabel,
  Format,
  Animation,
  Aggregation,
  AggregationInterval,
  ValueAxis,
  WholeRange,
  LoadingIndicator,
  Point,
  Font,
  Label,
  Crosshair,
  Strip,
  StripStyle,
  VisualRangeUpdateMode,
  ZoomAndPan,
  ScrollBar,
} from "devextreme-react/chart";

import { ExportExcel } from "../../perfomanceData/excelExport";

import { ZoomStartEvent } from "devextreme/viz/chart";

import ArrayStore from "devextreme/data/array_store";

import DataSource from "devextreme/data/data_source";

import {
  architectureSources,
  sharingStatisticsInfo,
  seriesTypeLabel,
} from "./data.js";

const EfficiencyOverTime = forwardRef((props, ref) => {
  const { lineData, currentMachine, group, toggleAllowUpdate } = props;

  //const [chartData, setChartData] = useState(null);
  const [firstMachineConfigMax, setfirstMachineConfigMax] = useState(0);
  const [firstMachineConfigMin, setfirstMachineConfigMin] = useState(100000);

  const chartRef = useRef(null);

  // useEffect(() => {
  //   if (allowUpdate) {
  //     setChartData(lineData);
  //   } else {
  //   }
  // }, [lineData]);

  var dataSource = new DataSource({
    store: new ArrayStore(lineData),
    paginate: false,
  });

  const zoomStart = (e) => {
    if (e.actionType === "zoom") {
      toggleAllowUpdate(false);
    }
  };

  const exportChart = useCallback((name) => {
    chartRef.current.instance.exportTo(name, "png");
  }, []);

  const exportExcel = async (data) => {
    data = data.map(({ Dummy, ...rest }) => rest);
    data.shift();
    ExportExcel(data, "DataExport", "DataExport");
  };

  useImperativeHandle(ref, () => ({
    // This will make `exportExcel` available to the parent component
    exportExcel,
    exportChart,
  }));

  // const minMaxValues = { max: 0, min: 100000 };

  useEffect(() => {
    currentMachine.machineConfigs
      .filter((item) => item.configGroup === group)
      .forEach((currentObject) => {
        if (
          currentObject.configMax > firstMachineConfigMax &&
          firstMachineConfigMax !== currentObject.configMax
        ) {
          setfirstMachineConfigMax(currentObject.configMax);
        }
        if (
          currentObject.configMin < firstMachineConfigMin &&
          currentObject.configMin !== firstMachineConfigMin
        ) {
          setfirstMachineConfigMin(currentObject.configMin);
        }
      });
  }, [currentMachine]);

  return (
    <React.Fragment>
      <Chart
        dataSource={dataSource}
        key={currentMachine?.id}
        ref={chartRef}
        onZoomStart={zoomStart}
      >
        {/* <ChartExport enabled={true} /> */}
        <CommonSeriesSettings
          argumentField="DateTime"
          type="spline"
        ></CommonSeriesSettings>
        <CommonAxisSettings allowDecimals="true">
          <Grid visible={true} />
        </CommonAxisSettings>

        <ZoomAndPan
          dragToZoom={true}
          allowMouseWheel={false}
          panKey="ctrl"
          argumentAxis="both"
          valueAxis="both"
        />

        {currentMachine.machineConfigs
          .filter((g) => g.configGroup == group)
          .filter((i) => i.inGraph == 1)
          .map((config) => (
            <Series
              key={config.id}
              valueField={config.configName}
              name={config.configName}
            >
              <Point visible={false} />
            </Series>
          ))}

        <ValueAxis
          valueType="numeric"
          visualRange={[firstMachineConfigMin, firstMachineConfigMax]}
        ></ValueAxis>

        <ChartMargin bottom={0} />
        <ArgumentAxis
          argumentType="datetime"
          axisDivisionFactor={60}
        ></ArgumentAxis>

        <Tooltip enabled={true} />
        <Legend verticalAlignment="top" horizontalAlignment="center" />
      </Chart>
    </React.Fragment>
  );
});

const areEqual = (prevProps, nextProps) => {
  if (nextProps.allowUpdate) {
    return false;
  } else {
    return true;
  }
  //return false; // donot re-render
};

export default memo(EfficiencyOverTime, areEqual);
