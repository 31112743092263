import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";

import axios from "axios";

import store from "./services/Store";

import { setCurrentUserAndCustomer } from "./login/loginSlice";
import reportWebVitals from "./reportWebVitals";

const { dispatch } = store; // direct access to redux store.

axios.interceptors.response.use(
  (response) => {
    if (response?.data?.user) {
      dispatch(setCurrentUserAndCustomer(response.data.user));
    }
    // if (response?.data?.token) {
    //   dispatch(setAccessToken(response.data.token));
    // }
    return response;
  },
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      console.log("UNATHORISED");
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
