import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAnalyticsData,
  getPerformanceData,
  getBlastingHoursData,
  getOnOffHoursData,
} from "./analyticsDataAPI";

const initialState = {
  analyticsDataStatus: "idle",
  analyticsDataError: null,
  analyticsData: [],
  performanceDataStatus: "idle",
  performanceDataError: null,
  performanceData: [],
  performanceConfigs: [],
  blastingHoursDataStatus: "idle",
  blastingHoursDataError: null,
  blastingHoursData: [],
  onOffHoursDataStatus: "idle",
  onOffHoursDataError: null,
  onOffHoursData: [],
};

export const getAnalyticsDataAsync = createAsyncThunk(
  "analyticsData/getAnalyticsData",
  async ({ machineId, startDate, endDate }) => {
    const response = await getAnalyticsData(machineId, startDate, endDate);
    return response.data;
  }
);

export const getPerformanceDataAsync = createAsyncThunk(
  "analyticsData/getPerformanceData",
  async ({ machineId, startDate, endDate }) => {
    const response = await getPerformanceData(machineId, startDate, endDate);
    return response.data;
  }
);

export const getBlastingHoursDataAsync = createAsyncThunk(
  "analyticsData/getBlastingHoursData",
  async ({ machineId, startDate, endDate }) => {
    const response = await getBlastingHoursData(machineId, startDate, endDate);
    return response.data;
  }
);

export const getOnOffHoursDataAsync = createAsyncThunk(
  "analyticsData/getOnOffHoursData",
  async ({ machineId, startDate, endDate }) => {
    const response = await getOnOffHoursData(machineId, startDate, endDate);
    return response.data;
  }
);

export const analyticsDataSlice = createSlice({
  name: "analyticsData",
  initialState,
  reducers: {
    clearAnalyticsData: (state, action) => {
      state.analyticsData = [];
    },
    clearPerformanceData: (state, action) => {
      state.performanceData = [];
    },
    clearBlastingHoursData: (state, action) => {
      state.blastingHoursData = [];
    },
    clearOnOffHoursData: (state, action) => {
      state.onOffHoursData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnalyticsDataAsync.pending, (state) => {
        state.analyticsDataStatus = "loading";
        state.analyticsDataError = null;
      })
      .addCase(getAnalyticsDataAsync.fulfilled, (state, action) => {
        state.analyticsData = action.payload.result;
        state.analyticsDataStatus = "idle";
      })
      .addCase(getAnalyticsDataAsync.rejected, (state, action) => {
        state.analyticsDataStatus = "failed";
        state.analyticsDataError = action.error.message;
      })
      .addCase(getPerformanceDataAsync.pending, (state) => {
        state.performanceDataStatus = "loading";
        state.performanceDataError = null;
      })
      .addCase(getPerformanceDataAsync.fulfilled, (state, action) => {
        state.performanceConfigs = action.payload.configs;

        const data = action.payload.result;
        const configs = action.payload.configs;

        const returnData = data.map((obj) => {
          const newObj = { DateTime: obj.DateTime };

          configs.map((config) => {
            if (obj.hasOwnProperty(config.configPort)) {
              newObj[config.configName] =
                obj[config.configPort] * config.VA_Multiplier;
            } else {
              newObj[config.configName] = 0;
            }
          });

          return newObj;
        });

        state.performanceData = returnData;
        state.performanceDataStatus = "idle";
      })
      .addCase(getPerformanceDataAsync.rejected, (state, action) => {
        state.performanceDataStatus = "failed";
        state.performanceDataError = action.error.message;
      })
      .addCase(getBlastingHoursDataAsync.pending, (state) => {
        state.blastingHoursDataStatus = "loading";
        state.blastingHoursDataError = null;
      })
      .addCase(getBlastingHoursDataAsync.fulfilled, (state, action) => {
        state.blastingHoursData = action.payload.result;
        state.blastingHoursDataStatus = "idle";
      })
      .addCase(getBlastingHoursDataAsync.rejected, (state, action) => {
        state.blastingHoursDataStatus = "failed";
        state.blastingHoursDataError = action.error.message;
      })
      .addCase(getOnOffHoursDataAsync.pending, (state) => {
        state.onOffHoursDataStatus = "loading";
        state.onOffHoursDataError = null;
      })
      .addCase(getOnOffHoursDataAsync.fulfilled, (state, action) => {
        state.onOffHoursData = action.payload.result;
        state.onOffHoursDataStatus = "idle";
      })
      .addCase(getOnOffHoursDataAsync.rejected, (state, action) => {
        state.onOffHoursDataStatus = "failed";
        state.onOffHoursDataError = action.error.message;
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAnalyticsData = (state) => state.analyticsData.analyticsData;

export const selectPerformanceData = (state) =>
  state.analyticsData.performanceData;

export const selectPerformanceConfigs = (state) =>
  state.analyticsData.performanceConfigs;

export const selectBlastingsHoursData = (state) =>
  state.analyticsData.blastingHoursData;

export const selectOnOffHoursData = (state) =>
  state.analyticsData.onOffHoursData;

export const selectAnalyticsDataQueryState = (state) =>
  state.analyticsData.analyticsDataStatus;

export const selectPerformanceDataQueryState = (state) =>
  state.analyticsData.performanceDataStatus;

export const selectBlastingQueryState = (state) =>
  state.analyticsData.blastingHoursDataStatus;

export const selectOnOffQueryState = (state) =>
  state.analyticsData.onOffHoursDataStatus;

export const {
  clearAnalyticsData,
  clearPerformanceData,
  clearBlastingHoursData,
  clearOnOffHoursData,
} = analyticsDataSlice.actions;

export default analyticsDataSlice.reducer;
