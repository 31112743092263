import http from "../services/httpService";
import config from "../config.json";

export async function getAnalyticsData(machineId, startDate, endDate) {
  if (machineId) {
    const response = await http.get(
      config.apiUrl + `/analytics/${machineId}/${startDate}/${endDate}`
    );

    return response;
  } else {
    return [];
  }
}

export async function getPerformanceData(machineId, startDate, endDate) {
  console.log("getPerformanceData", startDate, endDate);
  if (machineId) {
    const response = await http.get(
      config.apiUrl + `/performance/${machineId}/${startDate}/${endDate}`
    );

    return response;
  } else {
    return [];
  }
}

export async function getBlastingHoursData(machineId, startDate, endDate) {
  if (machineId) {
    const response = await http.get(
      config.apiUrl + `/blastinghours/${machineId}/${startDate}/${endDate}`
    );

    return response;
  } else {
    return [];
  }
}

export async function getOnOffHoursData(machineId, startDate, endDate) {
  console.log("getOnOffHoursData", startDate, endDate);
  if (machineId) {
    const response = await http.get(
      config.apiUrl + `/onoffhours/${machineId}/${startDate}/${endDate}`
    );

    return response;
  } else {
    return [];
  }
}
