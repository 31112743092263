import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
  useEffect,
} from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Chart,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
  Animation,
  ArgumentAxis,
  ValueAxis,
  Tick,
  Title,
  ZoomAndPan,
  CommonAxisSettings,
  Grid,
  Margin as ChartMargin,
  Tooltip,
  Series,
  Point,
} from "devextreme-react/chart";

import { selectPerformanceConfigs } from "../../../analyticsData/analyticsDataSlice";

import { ExportExcel } from "../../../perfomanceData/excelExport";

const PerformanceTime = forwardRef((props, ref) => {
  const {
    dataSource,
    visualRangeV,
    visualRangeH,
    currentMachine,
    group,
    setShowReset,
  } = props;

  const performanceConfigs = useSelector(selectPerformanceConfigs);

  const chartRef = useRef(null);

  const zoomStart = (e) => {
    if (e.actionType === "zoom") {
      setShowReset(true);
    }
  };

  const exportChart = useCallback((name) => {
    chartRef.current.instance.exportTo(name, "png");
  }, []);

  const exportExcel = async (data) => {
    data = data.map(({ Dummy, ...rest }) => rest);
    data.shift();

    ExportExcel(data, "DataExport", "DataExport");
  };

  useImperativeHandle(ref, () => ({
    // This will make `exportExcel` available to the parent component
    exportExcel,
    exportChart,
  }));

  return (
    <>
      <Chart
        dataSource={dataSource}
        key={currentMachine?.id}
        ref={chartRef}
        onZoomStart={zoomStart}
      >
        {/* <ChartExport enabled={true} /> */}
        <CommonSeriesSettings
          argumentField="DateTime"
          type="spline"
        ></CommonSeriesSettings>
        <CommonAxisSettings allowDecimals="true">
          <Grid visible={true} />
        </CommonAxisSettings>

        <ZoomAndPan
          dragToZoom={true}
          allowMouseWheel={false}
          panKey="ctrl"
          argumentAxis="both"
          valueAxis="both"
        />

        {currentMachine.machineConfigs
          .filter((g) => g.configGroup == group)
          .filter((i) => i.inGraph == 1)
          .map((config) => (
            <Series
              key={config.id}
              valueField={config.configName}
              name={config.configName}
            >
              <Point visible={false} />
            </Series>
          ))}

        <ArgumentAxis
          argumentType="datetime"
          axisDivisionFactor={60}
          visualRange={visualRangeV}
        ></ArgumentAxis>

        <ValueAxis
          position="left"
          visible={true}
          valueType="numeric"
          visualRange={visualRangeH}

          //visualRange={[firstMachineConfigMin, firstMachineConfigMax]}
        >
          <Title text="Amp" />
        </ValueAxis>

        <Title
          text={"Performance Data for " + group}
          subtitle="An Abbreviated Timeline"
        />
        <ChartMargin bottom={0} />

        <Tooltip enabled={true} />

        <Legend verticalAlignment="bottom" horizontalAlignment="center">
          <Title text="Performance Data" />
        </Legend>
      </Chart>
    </>
  );
});

export default PerformanceTime;
