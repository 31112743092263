import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";

import {
  Chart,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
  Animation,
  ArgumentAxis,
  ValueAxis,
  Tick,
  Title,
  ZoomAndPan,
  Label,
  Series,
  Tooltip,
} from "devextreme-react/chart";

import { ExportExcel } from "../../../perfomanceData/excelExport";

const OnOffChart = forwardRef((props, ref) => {
  const { dataSource, setShowReset, visualRangeV, visualRangeH } = props;

  const chartRef = useRef(null);

  const exportChart = useCallback((name) => {
    chartRef.current.instance.exportTo(name, "png");
  }, []);

  const exportExcel = async (data) => {
    data = data.map(({ Dummy, ...rest }) => rest);
    data.shift();
    ExportExcel(data, "DataExport", "DataExport");
  };

  useImperativeHandle(ref, () => ({
    // This will make `exportExcel` available to the parent component
    exportExcel,
    exportChart,
  }));

  const getPercent = (value) => {
    var perc = (value / 1440) * 100;
    return perc.toFixed(1);
  };
  const customizeText = (e) =>
    `${e.value + " minutes or " + getPercent(e.value) + "%"}`;
  return (
    <div>
      <Chart dataSource={dataSource} ref={chartRef} id="chart">
        <Title
          text={"Running - Non-Running"}
          subtitle="An Abbreviated Timeline"
        />
        <CommonSeriesSettings argumentField="date" type="stackedbar" />
        <Series valueField="ON" name="ON" />
        <Series valueField="OFF" name="OFF" />

        <ValueAxis position="left" visible={true}>
          <Title text="1 Day" />
        </ValueAxis>

        <Legend verticalAlignment="bottom" horizontalAlignment="center">
          <Title text="Performance Data" />
        </Legend>
        <Tooltip enabled={true} location="edge" contentRender={customizeText} />
      </Chart>
    </div>
  );
});

export default OnOffChart;
