import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPerformanceData,
  getPerformanceDataAsync,
} from "../../../analyticsData/analyticsDataSlice";
import "./performanceState.css";

const DataController = (props) => {
  const { updateChartData, currentMachine, reportDate, setDataReady } = props;

  const dispatch = useDispatch();
  const performanceData = useSelector(selectPerformanceData);

  const [machineId] = useState(currentMachine?.id);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [processed, setProcessed] = useState(null);

  const startDateRef = useRef(startDate);
  const endDateRef = useRef(endDate);

  useEffect(() => {
    //setEndDate(null);
    setStartDate(reportDate[0]);
    setEndDate(reportDate[1]);
  }, [reportDate]);

  useEffect(() => {
    startDateRef.current = startDate;
    endDateRef.current = endDate;
  }, [startDate, endDate]);

  const [lastReportStartDate, setLastReportStartDate] = useState(null);
  const [lastReportEndDate, setLastReportEndDate] = useState(null);

  // Fetch new analytics data when dates or machineId change
  useEffect(() => {
    // if (startDate !== lastReportStartDate && endDate !== lastReportEndDate) {
    //   setLastReportStartDate(startDate);
    //   setLastReportEndDate(endDate);

    if (startDate && endDate && startDate !== endDate) {
      setDataReady(false); // Show loading state before fetching new data
      dispatch(getPerformanceDataAsync({ machineId, startDate, endDate }));
    }

    // }
  }, [dispatch, machineId, startDate, endDate, setDataReady]);

  // Process data once new analytics data is fetched
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (performanceData) {
      setProcessed(performanceData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceData]); // Only run when analyticsData changes

  // Update chart data and set data ready to true after processing
  useEffect(() => {
    if (processed) {
      updateChartData(processed);
      setDataReady(true);
    }
  }, [processed, updateChartData, setDataReady]);

  return (
    <div className="controllerInner">
      {/* <ul className="configUList">
        {sortedConfigs.map((config) => (
          <React.Fragment key={"config-fragment-" + config.id}>
            <li className="lineItem">
              <div className="dx-field">
                <div className="dx-field-label">{config.configName}</div>
                <div className="dx-field-value">
                  <CheckBox
                    className="checkbox"
                    defaultValue={false}
                    iconSize={20}
                  />
                </div>
              </div>
            </li>
          </React.Fragment>
        ))}
      </ul> */}
    </div>
  );
};

export default DataController;
