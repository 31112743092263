import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import { addDummy } from "../perfomanceData/blasterDataSlice";

const AddDummyEntry = (props) => {
  const { componentId } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Dispatch your Redux action here
      dispatch(addDummy(componentId));
    }, 1000); // Call the action every 1000 milliseconds (1 second)

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return;
};

export default AddDummyEntry;
